import { IArgument, IDineshTicketOperations, requiresInput } from '@tymely/atoms';
import {
    AppMode,
    useAppMode,
    useCreateTicketCrumb,
    useTicketLoadingStatus,
    useTicket,
    useTicketOrganization,
    useUser,
} from '@tymely/services';
import { parseISO } from '@tymely/utils';
import React from 'react';
import { Loadable } from '@tymely/components';
import { Accordion, AccordionSummary, Box, Collapse, IconButton, styled, Typography } from '@mui/material';
import { datadogLogs } from '@datadog/browser-logs';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ArgumentList from './ArgumentList';
import { useArgumentsTabsContext } from './ArgumentsTabsProvider';
import useArgumentItems from './useArgumentItems';

const _Collapse = styled(Collapse)`
    margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const _CollapseDemo = styled(Collapse)`
    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const _DemoHeader = styled(Box)`
    display: flex;
    justify-content: space-between;
    width: 50%;
    padding-right: ${({ theme }) => theme.spacing(1)};
`;

const _DemoLabel = styled(Typography)`
    font-weight: 500;
    margin-right: ${({ theme }) => theme.spacing(1)};
    white-space: nowrap;
`;

const _IconButton = styled(IconButton)`
    padding: 0;
`;

const _Accordion = styled(Accordion)`
    box-shadow: 0;
    background: transparent;
    border: none;
`;

const _HighCondidenceLabel = styled(Typography, { shouldForwardProp: (prop: string) => !['shown'].includes(prop) })<{
    shown: boolean;
}>`
    ${({ shown }) => !shown && 'color: gray;'};
`;

const TextArgumentSection = () => {
    const { approvedArgs, argsLastUpdate, textArgs } = useArgumentsTabsContext();
    const ticket = useTicket();
    const user = useUser();
    const [showHighConfidenceArgs, setShowHighConfidenceArgs] = React.useState(false);
    const [openNonEdited, setOpenNonEdited] = React.useState(false);

    const [touchedArgs, setTouchedArgs] = React.useState<Set<IArgument['md_id']>>(new Set());

    const onTouch = React.useCallback(
        (arg: IArgument) => setTouchedArgs((ids) => new Set([...ids, arg.md_id])),
        [setTouchedArgs],
    );

    const nonEditedArgs = React.useMemo(() => {
        const argsLastEdit = argsLastUpdate ?? new Date();
        return textArgs.filter(
            (arg) =>
                parseISO(arg.created_at) < argsLastEdit ||
                requiresInput(arg) ||
                arg.special_value === 'unspecified' ||
                arg.special_value === 'unknown' ||
                arg.name === 'SelectedOrderItemSKUProxy' ||
                arg.name === 'SelectedOrderNumber',
        );
    }, [textArgs, argsLastUpdate]);

    const editedArgs = React.useMemo(() => {
        return textArgs.filter((arg) => !nonEditedArgs.find(({ id }) => arg.id === id));
    }, [textArgs, nonEditedArgs]);

    const { config } = useTicketOrganization();

    const [highConfidenceArgs, lowConfidenceArgs] = useArgumentItems();

    const createTicketCrumb = useCreateTicketCrumb();

    const { appMode } = useAppMode();

    const _approvedArgs = React.useMemo(() => {
        const appModes = [AppMode.Training, AppMode.Analyst];
        if (!!appMode && !appModes.includes(appMode)) {
            return approvedArgs;
        }
        return undefined;
    }, [appMode, approvedArgs]);

    const loadingStatus = useTicketLoadingStatus();

    return (
        <Loadable waitFor={textArgs}>
            {!!argsLastUpdate && (
                <>
                    <_Collapse in>
                        <ArgumentList
                            argumentItems={editedArgs}
                            onTouch={onTouch}
                            touchedArgIds={touchedArgs}
                            approvedArgs={_approvedArgs}
                            disabled={!!loadingStatus}
                        />
                    </_Collapse>
                    {config.demo && (
                        <>
                            <_DemoHeader onClick={() => setOpenNonEdited((open) => !open)}>
                                <_DemoLabel variant="subtitle1">Unchanged arguments</_DemoLabel>
                                <_IconButton size="small" disableRipple>
                                    {openNonEdited ? <ExpandLess /> : <ExpandMore />}
                                </_IconButton>
                            </_DemoHeader>
                            <_CollapseDemo in={openNonEdited}>
                                <ArgumentList
                                    disabled={!!loadingStatus}
                                    argumentItems={nonEditedArgs}
                                    onTouch={onTouch}
                                    touchedArgIds={touchedArgs}
                                    approvedArgs={_approvedArgs}
                                />
                            </_CollapseDemo>
                        </>
                    )}
                </>
            )}
            {!argsLastUpdate && (
                <>
                    {lowConfidenceArgs.length > 0 && (
                        <ArgumentList
                            disabled={!!loadingStatus}
                            argumentItems={lowConfidenceArgs}
                            onTouch={onTouch}
                            touchedArgIds={touchedArgs}
                            approvedArgs={_approvedArgs}
                        />
                    )}
                    {highConfidenceArgs.length > 0 && (
                        <_Accordion
                            expanded={showHighConfidenceArgs}
                            onChange={() => {
                                if (!showHighConfidenceArgs) {
                                    createTicketCrumb(IDineshTicketOperations.USER_REVEALED_HIDDEN_ARGS);
                                    datadogLogs.logger.info('USER_REVEALED_HIDDEN_ARGS', {
                                        username: user?.username,
                                        ticketId: ticket?.id,
                                    });
                                }
                                setShowHighConfidenceArgs((prev) => !prev);
                            }}
                            elevation={0}
                            disableGutters
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <_HighCondidenceLabel
                                    variant="subtitle2"
                                    data-qa="high-confidence-toggle"
                                    shown={showHighConfidenceArgs}
                                >
                                    {showHighConfidenceArgs ? 'Hide' : 'Show'} high confidence arguments
                                </_HighCondidenceLabel>
                            </AccordionSummary>
                            <ArgumentList
                                disabled={!!loadingStatus}
                                argumentItems={highConfidenceArgs}
                                onTouch={onTouch}
                                touchedArgIds={touchedArgs}
                                approvedArgs={_approvedArgs}
                            />
                        </_Accordion>
                    )}
                </>
            )}
        </Loadable>
    );
};

export default TextArgumentSection;
