import gravatarUrl from 'gravatar-url';
import React from 'react';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import Menu from '@mui/base/Menu';
import Input, { inputClasses } from '@mui/base/Input';
import {
    Avatar,
    Badge,
    Box,
    Divider,
    IconButton as IconButtonBase,
    IconButtonProps,
    styled,
    SxProps,
    Typography,
    useTheme,
} from '@mui/material';
import { useSearchTickets } from '@tymely/api';
import {
    useAppMode,
    useOrganizationsQuery,
    useSelectedOrganizations,
    SignOut,
    useFeatureFlags,
    useTicketCountQuery,
    useUser,
    useNotes,
    useSelectedChannels,
    useChannels,
    useSetSelectedChannels,
    TICKET_COUNT_QUERY_KEY,
    useTicket,
    useMultiSubscriptions,
    isQAChannel,
} from '@tymely/services';
import { routes } from '@tymely/config';
import { useKeys } from '@tymely/utils';
import HistoryIcon from '@tymely/components/Icons/History';
import SettingsIcon from '@tymely/components/Icons/Settings';
import ClientsIcon from '@tymely/components/Icons/Clients';
import ExitIcon from '@tymely/components/Icons/Exit';
import SearchIcon from '@tymely/components/Icons/Search';
import ResizeIcon from '@tymely/components/Icons/Resize';
import CircleIcon from '@mui/icons-material/Circle';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import { NavMenu, NavPopper, NavSelect } from '@tymely/components/Menu/NavMenu';
import { SelectMenu, GroupedSelectMenu } from '@tymely/components/Menu/SelectMenu';
import {
    BarItem,
    Drawer,
    DrawerContent as DrawerContentBase,
    LogoItem,
    NavList,
    Switch as NavSwitch,
} from '@tymely/components/SideBar';
import { AppMode, AppModes, IConsumptionChannel, IQueueInfo, ITicket } from '@tymely/atoms';
import { useQueryClient } from '@tanstack/react-query';
import { sum } from 'lodash';

import { TicketNotes } from './TicketNotes';
import { TicketsHistory } from './TicketsHistory';

const HistoryItem = React.memo(
    (props: { active: boolean; warning?: React.ReactNode; onActive: (active: boolean) => void }) => {
        return <BarItem title="History & Notes" icon={<HistoryIcon />} {...props} />;
    },
);

HistoryItem.displayName = 'HistoryItem';

const SETTINGS_OPTIONS = [
    { label: 'Policy Editor', value: `${routes.policyEditor}?graph` },
    { label: 'Arguments Metadata', value: routes.argumentMetadataView },
    { label: 'Actions Metadata', value: routes.actionMetadataView },
    { label: 'Template Editor', value: routes.templateEditor },
    { label: 'Organization Editor', value: routes.organizationEditor },
    { label: 'Prompt Editor', value: routes.promptEditor },
    { label: 'Ticket Exchange', value: routes.ticketExchange },
];

type SettingsItemProps = { onNavigate?: () => void };

const SettingsItem = ({ onNavigate }: SettingsItemProps) => {
    const location = useLocation();
    const navigate = useNavigate();

    const value = React.useMemo(
        () => SETTINGS_OPTIONS.find(({ value }) => value === location.pathname)?.value,
        [location.pathname],
    );

    const _onNavigate = (value: string) => {
        navigate(value);
        onNavigate?.();
    };

    return (
        <BarItem title="Settings" icon={<SettingsIcon />} hasMenu>
            {(anchorEl, open, setClose) => (
                <NavMenu
                    anchorEl={anchorEl}
                    open={open}
                    value={value}
                    options={SETTINGS_OPTIONS}
                    onSelect={(path) => {
                        _onNavigate(path);
                        setClose();
                    }}
                    onClose={setClose}
                />
            )}
        </BarItem>
    );
};

SettingsItem.displayName = 'SettingsItem';

const ChannelsItem = () => {
    const { appMode, isChat } = useAppMode();
    const { channels, isLoading: channelsAreLoading } = useChannels();
    const allSelectedChannels = useSelectedChannels();
    const selectedChannels = appMode == AppMode.QA ? allSelectedChannels.filter(isQAChannel) : allSelectedChannels;

    const setSelectedChannels = useSetSelectedChannels();
    const ticketCountQuery = useTicketCountQuery();

    const options = React.useMemo(() => {
        const orgTicketCount = (ticketCountQuery.data ?? []).reduce(
            (acc: Record<IQueueInfo['org_id'], IQueueInfo['ticket_count']>, qi) => ({
                [qi.org_id]: (acc[qi.org_id] ?? 0) + qi.ticket_count,
            }),
            {},
        );

        return ((channels || selectedChannels) ?? [])
            .filter((channel) => !channel.organization.disabled && !(appMode === AppMode.QA && !isQAChannel(channel)))
            .map((channel) => {
                const ticketCount = (ticketCountQuery.data ?? []).find(
                    (qi) =>
                        qi.org_id === channel.organization.id &&
                        qi.queue_name.includes(channel.queue_name.toLowerCase()),
                )?.ticket_count;

                let optLabel = channel.name;
                if (ticketCount) {
                    optLabel = optLabel + ` (${ticketCount})`;
                }

                let orgLabel = channel.organization.name;
                if (orgTicketCount[channel.organization.id]) {
                    orgLabel = orgLabel + ` (${orgTicketCount[channel.organization.id]})`;
                }

                return {
                    value: channel.uiId,
                    label: optLabel,
                    group: {
                        id: channel.organization.id,
                        label: orgLabel,
                    },
                };
            });
    }, [ticketCountQuery.data, channels, selectedChannels]);

    const onSelect = React.useCallback(
        (channelUiIds: string[]) => {
            setSelectedChannels((prevChannels) =>
                channels
                    .filter((channel) => channelUiIds.includes(channel.uiId))

                    // Preserve selected non-QA channels
                    .concat(appMode === AppMode.QA ? prevChannels.filter((ch) => !isQAChannel(ch)) : []),
            );
        },
        [setSelectedChannels, channels],
    );

    const values = React.useMemo(() => selectedChannels.map((channel) => channel.uiId), [selectedChannels]);
    const ticketCount = sum((ticketCountQuery.data ?? []).map((qi) => qi.ticket_count));

    return (
        <BarItem
            title="Clients"
            icon={
                <Badge badgeContent={ticketCount} color="primary">
                    <ClientsIcon />
                </Badge>
            }
            hasMenu
            disabled={isChat}
        >
            {(anchorEl, open, setClose) => (
                <GroupedSelectMenu
                    anchorEl={anchorEl}
                    open={open}
                    values={values}
                    options={options}
                    onSelect={onSelect}
                    onClose={setClose}
                    variant="nav"
                    multiple
                    selectedShownFirst
                    filter={options.length > 3}
                    filterPlaceholder={
                        channelsAreLoading
                            ? 'Loading...'
                            : `Search${options ? ` ${options.length}` : ''}${
                                  values.length ? ` (${values.length})` : ''
                              } channels...`
                    }
                    isLoading={channelsAreLoading}
                />
            )}
        </BarItem>
    );
};

const ShiftItem = (props: { sx?: SxProps }) => {
    const { switchShift, shiftEnd, isChat } = useAppMode();
    const onChange = React.useCallback(() => {
        switchShift();
    }, [switchShift]);
    return (
        <BarItem
            disabled={isChat}
            title="End online mode"
            sx={props.sx}
            icon={<NavSwitch checked={!shiftEnd} onLabel="On" offLabel="EOS" onChange={onChange} />}
        />
    );
};

const SearchPopper = styled(NavPopper)(
    ({ theme }) => `
    padding: 0;
    height: 66px;
    background: ${theme.nav.active};
`,
);

const SearchContainer = styled(Box)`
    display: flex;
    align-items: center;
    height: 100%;
    background: inherit;
    border-radius: inherit;
`;

const SearchInput = styled(Input)(
    ({ theme }) => `
    width: 35%;
    .${inputClasses.input} {
        width: 100%;
        background: ${theme.nav.active};
        outline: none;
        border: none;
        color: ${theme.palette.common.white};
        &::placeholder {
            color: #A7A9C1;
        }
    }
`,
);

const SearchMenu = React.memo((props: { anchorEl: HTMLLIElement | null; open: boolean; onClose: () => void }) => {
    const theme = useTheme();
    const { data: orgs } = useOrganizationsQuery();
    const selectedOrgs = useSelectedOrganizations();
    const [org, setSelectedOrg] = React.useState<number | undefined>();
    const [external, setExt] = React.useState(false);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const { appMode, navigateTicket } = useAppMode();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (props.open) {
            inputRef.current?.focus();
        }
    }, [props.open]);
    const options = React.useMemo(() => {
        return (orgs || selectedOrgs)?.map((org) => ({ label: org.name, value: org.id }));
    }, [orgs]);

    const onSwitch = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOrg(undefined);
        setExt(event.target.checked);
    }, []);

    const onSearch = React.useCallback(
        (ticketId: string) => {
            navigateTicket(ticketId, appMode === AppMode.Online ? AppMode.Training : appMode);
            props.onClose();
        },
        [navigateTicket, navigate, props.onClose, appMode],
    );

    const searchTickets = useSearchTickets();

    const controller = React.useRef(new AbortController());
    useKeys(
        ['Enter'],
        () => {
            if (!props.open) return;
            controller.current.abort();
            controller.current = new AbortController();
            const ticketId = inputRef.current?.value || '';
            if (org) {
                return searchTickets(
                    {
                        query: ticketId.trim(),
                        org,
                        maxResults: 1,
                    },
                    controller.current.signal,
                ).then((results) => {
                    if (results.length) {
                        onSearch(String(results[0].id));
                    }
                });
            }
            if (!external) {
                return onSearch(ticketId);
            }
        },
        { ref: inputRef },
    );

    return (
        <Menu
            anchorEl={props.anchorEl}
            open={props.open}
            slots={{ root: SearchPopper, listbox: SearchContainer }}
            onOpenChange={props.onClose}
        >
            <SearchInput slotProps={{ input: { ref: inputRef } }} placeholder="Search Ticket ID" />
            <NavSelect
                sx={{ opacity: external ? 1 : 0 }}
                value={org}
                options={props.open ? options : undefined}
                onSelect={setSelectedOrg}
            />
            <NavSwitch
                checked={external}
                sx={{ ml: 2, mr: 2, fontSize: theme.typography.fontSmall }}
                onLabel="Ext"
                offLabel="Int"
                onChange={onSwitch}
            />
        </Menu>
    );
});

SearchMenu.displayName = 'SearchMenu';

const SearchItem = () => {
    return (
        <BarItem title="Ticket Search" icon={<SearchIcon />} hasMenu>
            {(anchorEl, open, setClose) => <SearchMenu anchorEl={anchorEl} open={open} onClose={setClose} />}
        </BarItem>
    );
};

const ModeNavMenu = styled(NavMenu)`
    width: ${({ theme }) => theme.spacing(30.5)};
    max-height: fit-content;
    padding-left: ${({ theme }) => theme.spacing(3)};
` as typeof NavMenu;

const AppModeItem = () => {
    const { appMode, switchMode, isChat } = useAppMode();
    const colors = React.useMemo(() => ['#8DFEC8', '#9BB1FF', '#EB9BFF', '#FE7CA3', '#EECD77', '#FFF'], []);

    const options = React.useMemo(
        () =>
            Object.entries(AppModes).map(([value, label], index) => ({
                label,
                value,
                color: colors[index],
            })),
        [],
    );
    const option = React.useMemo(() => options.find((opt) => opt.value === appMode), [appMode, options]);
    const onSelect = React.useCallback(
        (value: AppMode) => {
            switchMode(value);
        },
        [switchMode],
    );

    const user = useUser();
    const theme = useTheme();

    const avatarSrc = React.useMemo(
        () =>
            gravatarUrl(user?.email || '', {
                size: 200,
                default: 'mm',
            }),
        [user],
    );

    return (
        <BarItem
            title={user?.username}
            icon={
                <Box sx={{ position: 'relative', width: 'auto' }}>
                    <Avatar
                        src={avatarSrc}
                        sx={{
                            height: theme.spacing(6),
                            width: theme.spacing(6),
                            background: theme.palette.secondary.main,
                        }}
                    />
                    {appMode && (
                        <CircleIcon
                            sx={{
                                fontSize: 16,
                                position: 'absolute',
                                right: theme.spacing(-0.25),
                                bottom: theme.spacing(-0.25),
                                color: option?.color,
                            }}
                        />
                    )}
                </Box>
            }
            hasMenu={!!appMode}
            disabled={isChat}
        >
            {appMode
                ? (anchorEl, open, setClose) => (
                      <ModeNavMenu
                          anchorEl={anchorEl}
                          open={open}
                          value={appMode}
                          options={options}
                          onSelect={(value) => {
                              onSelect(value as AppMode);
                              setClose();
                          }}
                          onClose={setClose}
                      />
                  )
                : undefined}
        </BarItem>
    );
};

const FeaturesItem = () => {
    const { features, enabledFeatureFlags: enabledFeatures, toggleFeatureFlag: toggleFeature } = useFeatureFlags();

    const options = React.useMemo(
        () => Object.entries(features).map(([key, value]) => ({ label: value, value: key })),
        [features],
    );

    const onSelect = React.useCallback((vals: string[]) => {
        for (const feature in features) {
            toggleFeature(feature, vals.includes(feature));
        }
    }, []);

    return (
        <BarItem title="Features" icon={<ScienceOutlinedIcon />} hasMenu>
            {(anchorEl, open, setClose) => (
                <SelectMenu
                    anchorEl={anchorEl}
                    open={open}
                    values={enabledFeatures}
                    options={options}
                    multiple
                    variant="nav"
                    onSelect={onSelect}
                    onClose={setClose}
                />
            )}
        </BarItem>
    );
};

export const IconButton = styled(IconButtonBase)`
    width: 26px;
    height: 26px;
    background-color: var(--ty-palette-common-white);
    cursor: pointer;
`;

const ResizeButton = styled((props: IconButtonProps) => (
    <IconButton {...props}>
        <ResizeIcon />
    </IconButton>
))`
    position: absolute;
    transform: translateY(-50%);
    right: 32px;
    border: 1px solid var(--ty-palette-divider);
    z-index: 1;
`;

const DrawerContent = React.memo(
    (props: { open: boolean; onClose: () => void; onRecentTickets: (tickets: ITicket[]) => void }) => {
        const [[startY, startH], setResize] = React.useState<[number?, number?]>([]);
        const notesRef = React.useRef<HTMLUListElement>(null);

        const onResizeStart = React.useCallback((event: React.MouseEvent) => {
            setResize([event.clientY, notesRef.current?.clientHeight]);
        }, []);

        const onResize = React.useCallback(
            (event: React.MouseEvent) => {
                if (startY === undefined || startH === undefined) return;
                notesRef.current!.style.height = startH + startY - event.clientY + 'px';
            },
            [startY, startH],
        );

        const onResizeStop = React.useCallback(() => {
            setResize([]);
        }, []);

        React.useEffect(() => {
            document.addEventListener('mouseup', onResizeStop);
            return () => document.removeEventListener('mouseup', onResizeStop);
        }, []);

        return (
            <DrawerContentBase sx={{ display: props.open ? 'block' : 'none' }} onClose={props.onClose}>
                <Box display="flex" flexDirection="column" height={1} onMouseMove={onResize}>
                    <TicketsHistory onRecentTickets={props.onRecentTickets} />
                    <Box position="relative">
                        <Divider sx={{ borderColor: 'var(--ty-palette-divider)' }} />
                        <ResizeButton onMouseDown={onResizeStart} />
                    </Box>
                    <TicketNotes ref={notesRef} />
                </Box>
            </DrawerContentBase>
        );
    },
);

DrawerContent.displayName = 'DrawerContent';

const useSubscribeSelectedChannels = () => {
    const selectedChannels = useSelectedChannels();
    const queryClient = useQueryClient();
    const ticketCountQuery = useTicketCountQuery();

    const channels = React.useMemo(
        () =>
            selectedChannels.reduce<Record<string, IConsumptionChannel>>((memo, channel) => {
                const channelName = `organization/${channel.organization.id}/${channel.name}`;
                memo[channelName] = channel;
                return memo;
            }, {}),
        [selectedChannels],
    );

    const channelNames = React.useMemo(() => Object.keys(channels), [channels]);

    useMultiSubscriptions<{ data: IQueueInfo }>(
        channelNames,
        ({ data }, channel) => {
            queryClient.setQueryData(
                [TICKET_COUNT_QUERY_KEY],
                [
                    ...(ticketCountQuery.data ?? []).filter(
                        (qi) => qi.org_id !== data.org_id || qi.queue_name !== channels[channel].queue_name,
                    ),
                    data,
                ],
            );
        },
        [],
    );
};

export const AppBar = React.memo(() => {
    const theme = useTheme();
    const ticket = useTicket();
    const location = useLocation();
    const ticketRoute = Boolean(
        matchRoutes([{ path: `${routes.ticket}/*` }, { path: `${routes.onlineTicket}/*` }], location),
    );
    const { appMode, isOnline } = useAppMode();
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const user = useUser();
    const onClose = React.useCallback(() => setDrawerOpen(false), []);
    const [recentTickets, setRecentTickets] = React.useState<ITicket[]>([]);
    const { features } = useFeatureFlags();
    const hasFeatures = Object.keys(features).length > 0;

    const onActive = React.useCallback(() => {
        setDrawerOpen((active) => !active);
        setRecentTickets([]);
    }, []);
    useSubscribeSelectedChannels();

    const { notes } = useNotes();
    React.useEffect(() => {
        if (notes.length > 0 && ![AppMode.Admin, AppMode.Analyst].includes(appMode!)) {
            setDrawerOpen(true);
        }
    }, [notes, setDrawerOpen]);

    return (
        <Drawer variant="permanent" open={ticketRoute && ticket && drawerOpen}>
            <Box
                display="flex"
                zIndex={theme.zIndex.appBar}
                flexDirection="column"
                justifyContent="space-between"
                height={1}
            >
                <NavList sx={{ height: theme.spacing(25), pt: 4.5 }}>
                    <AppModeItem />
                    {appMode && (
                        <Typography
                            variant="body2"
                            color="common.white"
                            textTransform="capitalize"
                            textAlign="center"
                            mb={3}
                        >
                            {AppModes[appMode]}
                        </Typography>
                    )}
                    {isOnline && <ShiftItem sx={{ pt: 0, pb: 0 }} />}
                </NavList>
                <NavList sx={{ flex: 1 }}>
                    {(ticketRoute || appMode === AppMode.Online) && ticket && (
                        <HistoryItem
                            active={drawerOpen}
                            onActive={onActive}
                            warning={
                                recentTickets.length && (
                                    <Box>
                                        {'Relevant'}
                                        <br />
                                        {'context?'}
                                    </Box>
                                )
                            }
                        />
                    )}
                    <ChannelsItem />
                    {user?.isAdmin && <SettingsItem onNavigate={onClose} />}
                    <SearchItem />
                    {hasFeatures && <FeaturesItem />}
                </NavList>
                <NavList>
                    <BarItem title="Logout" icon={<ExitIcon />} onClick={SignOut} />
                    <LogoItem />
                </NavList>
            </Box>
            {ticketRoute && ticket && (
                <DrawerContent
                    open={drawerOpen}
                    onClose={onClose}
                    onRecentTickets={(tickets) => {
                        if (tickets.length > 0 && ![AppMode.Admin, AppMode.Analyst].includes(appMode!)) {
                            setDrawerOpen(true);
                        }
                        setRecentTickets(tickets);
                    }}
                />
            )}
        </Drawer>
    );
});

AppBar.displayName = 'AppBar';
