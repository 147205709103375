import { ITicket } from '@tymely/atoms';

import { useApi } from './axios';

export const useSearchTickets = () => {
    const api = useApi();
    return (params: { query: string; org: number; maxResults?: number }, signal?: AbortSignal): Promise<ITicket[]> =>
        api.get('tickets/search', {
            params: {
                search_term: params.query,
                org_id: params.org,
                max_results: params.maxResults,
            },
            signal,
        }) as Promise<ITicket[]>;
};
