import { parse } from 'csv-parse/browser/esm/sync';
import { ILlmReportRecord, ILlmSummaryReport } from '@tymely/atoms';

import { useApi } from './axios';

const parseReportCsv = (report: string) => {
    return parse(report, {
        cast: (value, context) => {
            switch (context.column) {
                case 'arg_value':
                    return value === 'None' ? null : value;
                case 'arg_id':
                case 'comment_id':
                case 'ticket_id':
                case 'experiment_arg_md_id':
                case 'llm_trail_id':
                case 'optimize_target_arg_md_id':
                    return parseInt(value);
                case 'predicted_confidence':
                    return parseFloat(value);
                case 'predicted_correctly':
                    return value === 'True';
            }
            return value;
        },
        columns: true,
        skip_empty_lines: true,
        record_delimiter: '\n',
    }) as ILlmReportRecord[];
};

export const useFetchLlmExperimentReport = () => {
    const api = useApi();
    return async (report_link: string) => {
        const csv = (await api.get(report_link, {
            headers: {
                Authorization: null,
            },
        })) as string;
        return parseReportCsv(csv);
    };
};

const parseSummaryReportCsv = (report: string) => {
    return parse(report, {
        cast: true,
        columns: true,
        skip_empty_lines: true,
        record_delimiter: '\n',
    }) as ILlmSummaryReport[];
};

export const useFetchLlmExperimentSummaryReport = () => {
    const api = useApi();
    return async (summary_report_link: string) => {
        const csv = (await api.get(summary_report_link, {
            headers: {
                Authorization: null,
            },
        })) as string;
        return parseSummaryReportCsv(csv)[0];
    };
};
