import { IArgument, requiresInput } from '@tymely/atoms';
import { AppMode, useAppMode, useTicket } from '@tymely/services';
import { partition } from 'lodash';
import React from 'react';

import { useArgumentsTabsContext } from './ArgumentsTabsProvider';

const KEY = 'hidden-on-tickets';

const TICKET_IDS_CACHE: Record<string, number[]> = {};

const getArgTicketIds = (argId: number) => {
    let ticketIds = TICKET_IDS_CACHE[`${argId}-${KEY}`];
    if (!ticketIds) {
        const rawValue = localStorage.getItem(`${argId}-${KEY}`);
        if (rawValue) {
            ticketIds = JSON.parse(rawValue) as number[];
        }
    }
    return ticketIds ?? [];
};

// Check TYM-4518 for more info about sampling
const isHighConfidenceArgumentSampled = (ticketId?: number) => (arg: IArgument) => {
    const isHighConfidence = arg.high_confidence;
    if (!ticketId) {
        return isHighConfidence;
    }
    if (!isHighConfidence) {
        return false;
    }

    const ticketIds = getArgTicketIds(arg.id);
    const isAlreadyShownOnTicket = ticketIds.includes(ticketId);
    if (!isAlreadyShownOnTicket) {
        ticketIds.push(ticketId);
        localStorage.setItem(`${arg.md_id}-${KEY}`, JSON.stringify(ticketIds));
        TICKET_IDS_CACHE[`${arg.id}-${KEY}`] = ticketIds;
    }

    return ticketIds.length % 10 !== 0;
};

const useArgumentItems = () => {
    const { textArgs } = useArgumentsTabsContext();
    const ticket = useTicket();
    const { appMode } = useAppMode();
    const isHighConfidence = isHighConfidenceArgumentSampled(ticket?.id);

    return React.useMemo(
        () =>
            appMode == AppMode.QA
                ? [[], textArgs] // No high-confidence section in QA mode
                : partition(textArgs, (arg) => isHighConfidence(arg) && !arg.is_edited && !requiresInput(arg)),
        [textArgs, ticket?.id],
    );
};

export default useArgumentItems;
