import { atom } from 'recoil';

export interface IUser {
    username?: string;
    isAdmin?: boolean;
    email: string;
}

export const userAtom = atom<IUser | undefined>({
    key: 'user',
    default: undefined,
});
