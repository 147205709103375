import { Nullable } from '@global/types';
import { atom } from 'recoil';

import { ExecutionStatus, WorkflowStatus } from './policy.atoms';
import { IPolicySet } from './policySet.atoms';

export interface IComment {
    updated_at: string;
    created_date: string;
    body: string;
    body_html?: string;
    channel: string;
    from_email: string;
    from_name: string;
    id: number;
    ticket_id: number;
    inquiry_date: string;
    submitted_at: Nullable<string>;
    is_customer: boolean;
    original_id_from_client: string;
    response_body?: Nullable<string>;
    selected_intent_id: Nullable<number>;
    infer_data?: Nullable<IInferData>;
    additional_data: Record<string, unknown> & {
        highlight_spans: [start: number, end: number][];
        decisions: Record<IPolicySet['id'], { decision: IDecision }>;
        multiple_intents?: number[];
        country_code?: string;
        issue_type?: string;
    };
    agent_username?: string;
    is_public?: boolean;
    attachment_links: string[];
    is_automated_response: boolean;
}

export const ticketSessionId = atom<string | undefined>({
    key: 'sessionId',
    default: undefined,
});

export const historicAnalysis = atom<IComment | undefined>({
    key: 'historicDecision',
    default: undefined,
});

export const commentIdToFocusOnAtom = atom<number | undefined>({
    key: 'ticketCommentIdToFocusOn',
    default: undefined,
});

export const submittingComments = atom<{ [key: IComment['id']]: boolean }>({
    key: 'submittingComments',
    default: {},
});

export const isPolicyEvaluated = atom<boolean>({
    key: 'isPolicyEvaluated',
    default: false,
});

export const isPolicyEvaluationRunning = atom<boolean>({
    key: 'policyEvaluationRunning',
    default: false,
});

export interface IInferIntent {
    weight: number;
    intent_id: number;
}

export interface IInferData {
    intents: IInferIntent[];
    model_version: string;
}

export interface IAction {
    id: number;
    title: string;
    description: string;
    executor_name: string;
    workflow_id: number;
    order: number;
    action_metadata_id: number;
    action_metadata: {
        title: string;
    };
    execution_status: ExecutionStatus;
    args: { template_id: number };
}

export interface IActionExecution {
    id: number;
    decision_id: number;
    action_metadata_id: number;
    action_metadata: { title: string };
    order: number;
    status: ExecutionStatus;
    execute_start_at: Nullable<Date>;
    execute_end_at: Nullable<Date>;
    additional_data: object;
    args: { template_id: number };
    run_on_fail: boolean;
}

export interface IWorkflowExplanation {
    did_hit: boolean;
    wf_id: number;
    wf_title?: string;
    condition_explanations: string[];
}

export interface IDecision {
    created_date: Nullable<Date>;
    title: string;
    status: WorkflowStatus;
    execution_status: ExecutionStatus;
    explanation: IWorkflowExplanation[];
    action_executions: Array<IActionExecution>;
    workflow_id: number;
    decision_lineage: string[];
    condition_lineage: string[];
    auto: boolean;
}
