import { ArgMetadatadInUseDetails, IOrganization, ITicket } from '@tymely/atoms';
import { AxiosError } from 'axios';

export const templateErrorTypes = ['unknown', 'syntax', 'missing_argument'];
export type TemplateErrorType = (typeof templateErrorTypes)[number];

export interface ITemplateError {
    type: TemplateErrorType;
    description: string;
    location?: {
        line?: number;
        char?: number;
    };
}

export type ErrorCode =
    | 'NO_POLICY'
    | 'STALE_TICKET_UPDATE'
    | 'ARGUMENT_METADATA_IN_USE'
    | 'TEMPLATE_ERROR'
    | 'BAD_SCHEMA'
    | 'NON_EMPTY_ITEM_OVERWRITE'
    | 'TICKET_IS_LOCKED';

export class ApiError<C extends ErrorCode = ErrorCode, D = unknown> extends AxiosError {
    override code: C;
    detail: D;

    constructor(code: C, message: string, detail: D) {
        super(message);
        this.message = message;
        this.code = code;
        this.detail = detail;
    }
}

export interface ISchemaError {
    detail: {
        msg: string;
        type: string;
        loc: (string | number)[];
    }[];
}

export class ArgumentUsedError extends ApiError<'ARGUMENT_METADATA_IN_USE', ArgMetadatadInUseDetails> {
    constructor(message: string, detail: ArgMetadatadInUseDetails) {
        super('ARGUMENT_METADATA_IN_USE', message, detail);
    }
}

export class NoPolicyError extends ApiError<'NO_POLICY', null> {
    constructor(message: string) {
        super('NO_POLICY', message, null);
    }
}

export class StaleTicketUpdate extends ApiError<'STALE_TICKET_UPDATE', null> {
    constructor(message: string) {
        super('STALE_TICKET_UPDATE', message, null);
    }
}

type TicketIsLockedDetail = {
    organization_id: IOrganization['id'];
    origin_ticket_id: ITicket['original_id_from_client'];
    state: string;
    action: 'wait' | 'abort';
};

export class TicketIsLockedError extends ApiError<'TICKET_IS_LOCKED', TicketIsLockedDetail> {
    constructor(detail: TicketIsLockedDetail) {
        super('TICKET_IS_LOCKED', 'Ticket is being processed', detail);
    }
}

export class TemplateError extends ApiError<'TEMPLATE_ERROR', ITemplateError[]> {
    constructor(message: string, detail: ITemplateError[]) {
        super('TEMPLATE_ERROR', message, detail);
    }
}

export class SchemaError extends ApiError<'BAD_SCHEMA', ISchemaError> {
    constructor(message: string, detail: ISchemaError) {
        super('BAD_SCHEMA', message, detail);
    }
}

export class NonEmptyItemOverwrite extends ApiError<'NON_EMPTY_ITEM_OVERWRITE', null> {
    constructor(message: string) {
        super('NON_EMPTY_ITEM_OVERWRITE', message, null);
    }
}
