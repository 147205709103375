import { AxiosError } from 'axios';
import { JSONSchema7 } from 'json-schema';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ILlmExperiment, ILlmExperimentCreate, LlmExperimentDataSetType } from '@tymely/atoms';
import { useApi } from '@tymely/api';

import { useSetAlert } from './alerts.services';

export const LLM_EXPERIMENTS_SEARCH_QUERY_KEY = 'llm-experiments-history';
export const ORGANIZATION_SCHEMA_QUERY_KEY = 'organization-schema';

export const useLlmExperimentsSearch = (
    experimentType: ILlmExperiment['experiment_type'],
    optimizationTargetName: string,
    enabled = true,
) => {
    const api = useApi();
    return useQuery<ILlmExperiment[]>({
        queryKey: [LLM_EXPERIMENTS_SEARCH_QUERY_KEY, experimentType, optimizationTargetName],
        queryFn: () =>
            api.get('llm-experiments/search', {
                params: {
                    experiment_type: experimentType,
                    optimization_target_name: optimizationTargetName,
                },
            }),
        enabled,
    });
};

export const useCreateLlmExperiment = () => {
    const api = useApi();
    const setAlert = useSetAlert();
    const queryClient = useQueryClient();
    return useMutation<ILlmExperiment, AxiosError, ILlmExperimentCreate>({
        mutationFn: (llmExperiment) => api.post('llm-experiment', llmExperiment),
        onSuccess: (llmExperiment) =>
            queryClient.invalidateQueries({
                queryKey: [
                    LLM_EXPERIMENTS_SEARCH_QUERY_KEY,
                    llmExperiment.experiment_type,
                    llmExperiment.additional_data.optimization_target_name,
                ],
            }),
        onError: (error) => {
            setAlert(error.message, 'error', 5000, 'Failed creating LLM experiment.');
        },
    });
};

export const useRunLlmExperiment = () => {
    const api = useApi();
    return useMutation({
        mutationFn: ({
            experimentId,
            dataset,
        }: {
            experimentId: ILlmExperiment['id'];
            dataset:
                | {
                      type: LlmExperimentDataSetType;
                      ids: number[];
                      name: string;
                  }
                | undefined;
        }) => api.put(`llm-experiment/${experimentId}/run-offline`, { dataset }),
    });
};

export const useLlmExperimentQuery = (llmExperimentId?: ILlmExperiment['id'], enabled = true) => {
    const api = useApi();
    return useQuery({
        queryKey: [LLM_EXPERIMENTS_SEARCH_QUERY_KEY, llmExperimentId],
        queryFn: () => api.get(`llm-experiment/${llmExperimentId}`) as Promise<ILlmExperiment>,
        enabled: enabled && !!llmExperimentId,
    });
};

export const useLlmExperimentSchemaQuery = () => {
    const api = useApi();
    return useQuery({
        queryKey: [ORGANIZATION_SCHEMA_QUERY_KEY],
        queryFn: () => api.get('llm-experiment/schema') as Promise<JSONSchema7>,
    });
};

export const useUpsertLlmExperiment = () => {
    const api = useApi();
    return useMutation({
        mutationFn: (experiment: ILlmExperiment) => api.put(`llm-experiment/${experiment.id}`, experiment),
    });
};

export const useLlmExperimentCreateReportMutation = () => {
    const api = useApi();
    return useMutation({
        mutationFn: (llmExperimentId: ILlmExperiment['id']) =>
            api.get(`llm-experiment/${llmExperimentId}/create-report`),
    });
};
