import React from 'react';
import { IUser } from '@tymely/atoms';

import { useUser } from './auth.services';
import { usePersistentEvent, useSubscription } from './pubsub.services';

export const useViewEvent = (channel: string, event: string) => {
    const user = useUser();
    usePersistentEvent(channel, { event, agent: user }, [user]);
};

type Viewer = IUser & { viewedAt: Date };

type ViewEvent<T extends string> = { event: T; agent: IUser };

export const useViewListener = <T extends string = string>(channel: string, event: T) => {
    const [records, setRecords] = React.useState<Record<string, Viewer>>({});
    const user = useUser();

    useSubscription<ViewEvent<T>>(
        channel,
        ({ agent, event: _event }) => {
            if (agent && agent.username !== user?.username && _event === event) {
                setRecords((prevValue) => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    if (prevValue[agent.email!]) {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        prevValue[agent.email].viewedAt = new Date();
                    } else {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        prevValue[agent.email] = { ...agent, viewedAt: new Date() };
                    }
                    return { ...prevValue };
                });
            }
        },
        undefined,
        [user],
    );

    React.useEffect(() => {
        const interval = setInterval(() => {
            setRecords((prevValue) =>
                Object.fromEntries(
                    Object.entries(prevValue).filter(([_, value]) => Date.now() - value.viewedAt.getTime()),
                ),
            );
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    React.useEffect(() => setRecords({}), [channel]);

    return Object.values(records);
};
