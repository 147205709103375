import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { TemplateError, useApi } from '@tymely/api';
import { ITemplateVariable, IResponseTemplate } from '@tymely/atoms';

export const RESPONSE_TEMPLATE_QUERY_KEY = 'response-template';
export const RENDER_TEMPLATE_QUERY_KEY = 'render-template';
export const FREE_VARS_QUERY_KEY = 'free-vars';

export const useFetchResponseQuery = (templateId?: number) => {
    const api = useApi();
    return useQuery<IResponseTemplate | null, TemplateError>({
        queryKey: [RESPONSE_TEMPLATE_QUERY_KEY, templateId],
        queryFn: () => (templateId ? api.get(`response-template/${templateId}`) : Promise.resolve(null)),
        enabled: Boolean(templateId),
        meta: {
            errorMessage: `Failed to load template ${templateId}`,
        },
    });
};

export const useRenderTemplateQuery = (
    template: string,
    variables: Record<string, ITemplateVariable>,
    sanitize?: boolean,
    enabled = true,
) => {
    const api = useApi();
    return useQuery<string>({
        queryKey: [RENDER_TEMPLATE_QUERY_KEY, template, variables, sanitize],
        queryFn: () => api.post('template/render', { template, variables, sanitize }),
        enabled,
        retry: false,
    });
};

export const useTemplateFreeVariablesQuery = (template: string) => {
    const api = useApi();
    return useQuery<string[]>({
        queryKey: [FREE_VARS_QUERY_KEY, template],
        queryFn: () => api.post('template/free-variables', { template }),
        retry: false,
    });
};

export const EDIT_TEMPLATE_MUTATION_KEY = 'edit-response-template';
export const RESPONSE_TEMPLATE_MUTATION_KEY = 'response-template';

export const useEditResponseTemplateMutation = () => {
    const api = useApi();
    const queryClient = useQueryClient();
    return useMutation<IResponseTemplate, AxiosError | TemplateError, IResponseTemplate>({
        mutationKey: [EDIT_TEMPLATE_MUTATION_KEY],
        mutationFn: (template) => api.put(`response-template/${template.id}`, template),
        onSuccess(data) {
            queryClient.setQueryData([RESPONSE_TEMPLATE_MUTATION_KEY, data.id], () => data);
        },
    });
};

export const useCreateResponseTemplateMutation = () => {
    const api = useApi();
    const queryClient = useQueryClient();
    return useMutation<IResponseTemplate, AxiosError, Omit<IResponseTemplate, 'id'>>({
        mutationFn: (template) => api.post('response-template', template),
        onSuccess: (data) => {
            queryClient.setQueryData([RESPONSE_TEMPLATE_MUTATION_KEY, data.id], () => data);
        },
    });
};
