import { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { IDineshTicketOperations, TicketReportCategory } from '@tymely/atoms';
import { useApi } from '@tymely/api';

import { useUser } from './auth.services';
import { useTicket, useSetTicketProps, useUpsertNotes } from './ticket.services';
import { useSelectedComment, useSelectedCommentIntents } from './comment.services';
import { useCreateTicketCrumb } from './ticketTrail.services';

export const TICKET_REPORT_CATEGORIES_QUERY_KEY = 'ticket-report-categories';

export const useNotes = () => {
    const ticket = useTicket();
    const user = useUser();
    const setTicketProps = useSetTicketProps();
    const createTicketCrumb = useCreateTicketCrumb();
    const selectedComment = useSelectedComment();
    const upsertNotes = useUpsertNotes();

    const notes = useMemo(() => {
        const savedNote = ticket?.notes?.note;
        return (ticket?.notes?.notes || [])
            .concat(savedNote ? [{ text: savedNote }] : [])
            .sort((a, b) => new Date(b.datetime || 0).valueOf() - new Date(a.datetime || 0).valueOf());
    }, [ticket?.notes]);

    const saveNote = useCallback(
        async (note: string, escalate?: boolean) => {
            const data = {
                ...ticket.notes,
                notes: [
                    ...notes,
                    {
                        text: note,
                        escalate,
                        username: user?.username,
                        email: user?.email,
                        datetime: new Date().toISOString(),
                    },
                ],
            };
            setTicketProps({ notes: data });
            createTicketCrumb(IDineshTicketOperations.USER_SAVED_NOTES, { data: data });
            return upsertNotes.mutateAsync({
                commentId: selectedComment!.id,
                notes: data,
            });
        },
        [ticket, notes, user, setTicketProps],
    );

    const editNote = useCallback(
        async (note: string, index: number) => {
            const nextNotes = [...notes];
            nextNotes[index] = { ...nextNotes[index], text: note };
            setTicketProps({ notes: { ...ticket.notes, notes: nextNotes } });
            return upsertNotes.mutateAsync({
                commentId: selectedComment!.id,
                notes: { notes: nextNotes },
            });
        },
        [ticket, notes],
    );

    const wasEscalated = useMemo(() => ticket?.notes?.report?.escalate, [ticket]);

    return { notes, reportNote: ticket?.notes?.report, saveNote, editNote, wasEscalated };
};

export const useTicketReport = () => {
    const api = useApi();
    const ticket = useTicket();
    const user = useUser();
    const selectedComment = useSelectedComment();
    const selectedCommentIntents = useSelectedCommentIntents();
    const setTicketProps = useSetTicketProps();
    const createTicketCrumb = useCreateTicketCrumb();
    const upsertNotes = useUpsertNotes();

    const { data: reportCategories = [] } = useQuery({
        queryKey: [TICKET_REPORT_CATEGORIES_QUERY_KEY],
        queryFn: () => api.get('tickets/report-categories'),
    });

    const report = useCallback(
        async (category: TicketReportCategory, description: string, escalate = false) => {
            if (!ticket) return;

            const notes = {
                ...ticket.notes,
                report: {
                    category,
                    description,
                    datetime: new Date().toISOString(),
                    username: user?.username,
                    intent_ids: selectedCommentIntents.map((i) => i.id),
                    escalate,
                },
            };
            setTicketProps({ notes });
            createTicketCrumb(
                escalate ? IDineshTicketOperations.USER_ESCALATED_TICKET : IDineshTicketOperations.USER_REPORTED_TICKET,
                { data: notes },
            );
            return upsertNotes.mutateAsync({ commentId: selectedComment!.id, notes });
        },
        [ticket, setTicketProps],
    );

    return {
        reportCategories,
        report,
    };
};
