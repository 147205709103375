import React, {
    FunctionComponent,
    useCallback,
    useState,
    useMemo,
    useTransition,
    useEffect,
    useRef,
    ForwardedRef,
    useImperativeHandle,
} from 'react';
import { useHoverDirty } from 'react-use';
import {
    Checkbox,
    Box,
    ListItemText,
    MenuItem,
    Select,
    styled,
    Typography,
    SelectProps,
    SelectClasses,
    useTheme,
    ListSubheader,
    SxProps,
    Theme,
    TextField,
    menuItemClasses,
    selectClasses,
    alpha,
    Input,
    CircularProgress,
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { typedMemo, Nullable, typedForwardRef } from '@global/types';
import debounce from 'lodash/debounce';

type Group = { id: string; name: string };

export type Option<T> = {
    value: T;
    name: string;
    group?: Group;
};

const SelectOnlyTypography = styled(Typography)(({ theme }) => ({
    '&:hover': {
        color: theme.palette.info.main,
    },
}));

const ListItemButtonLabel = styled(ListItemText)(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.primary.main,
}));

const StyledSelect = styled(Select)(() => ({
    width: '100%',
})) as FunctionComponent<SelectProps<string>>;

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.modal,
    backgroundColor: theme.palette.background.paper,
    pt: 1,
    [`&.${menuItemClasses.focusVisible}`]: {
        backgroundColor: theme.palette.background.paper,
    },
    '&:hover': {
        backgroundColor: theme.palette.background.paper,
    },
}));

type OT = boolean | string | number;

const useMenuSearch = <T extends OT | OT[]>(options: Option<T>[], ignore = false, hint?: string, disabled = false) => {
    const [, startTransition] = useTransition();
    const [searchInput, setSearchInput] = useState('');
    const [searchResults, setSearchResults] = useState(options);

    const onSearch = useMemo(
        () =>
            debounce((e: React.ChangeEvent<HTMLInputElement>) => {
                startTransition(() => {
                    const value = e.target.value;
                    setSearchResults(
                        options.filter(
                            (option) =>
                                option.name.toLowerCase().includes(value.toLowerCase()) ||
                                (option.group && option.group.name.toLowerCase().includes(value.toLowerCase())),
                        ),
                    );
                });
            }, 300),
        [options],
    );

    return {
        searchResults,
        searchTerm: searchInput,
        searchActive: searchInput.length > 0,
        searchMenuItem: !ignore && (
            <StyledMenuItem disabled={disabled}>
                <TextField
                    variant="outlined"
                    fullWidth
                    disabled={disabled}
                    placeholder={hint ?? 'Search...'}
                    inputProps={{
                        value: searchInput,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            setSearchInput(e.currentTarget.value);
                            if (e.currentTarget.value) {
                                onSearch(e);
                            } else {
                                startTransition(() => {
                                    setSearchResults(options);
                                });
                            }
                        },
                        onKeyDown: (e) => {
                            if (!['ArrowDown', 'ArrowUp', 'Home', 'End'].includes(e.key)) {
                                e.stopPropagation();
                            }
                        },
                    }}
                    size="small"
                />
            </StyledMenuItem>
        ),
    };
};

export const CheckMenuItem = typedMemo(
    <T extends OT | OT[]>(props: {
        item: Option<T>;
        selected: boolean;
        withCheckbox?: boolean;
        disabled?: boolean;
        size?: 'small' | 'medium';
        sx?: SxProps<Theme>;
        onSelect?: (opt: Option<T>) => void;
        onClick?: (opt: Option<T>) => void;
        showOnly?: boolean;
        attrs?: React.HTMLAttributes<HTMLLIElement>;
    }) => {
        const theme = useTheme();
        const [isHovered, setIsHovered] = useState(false);

        const onSelect = useCallback(
            (e: React.FormEvent<HTMLDivElement>) => {
                props.onSelect?.(props.item);
                e.stopPropagation();
            },
            [props.onSelect],
        );

        const onClick = useCallback(() => {
            props.onClick?.(props.item);
        }, [props.onClick]);

        return (
            <MenuItem
                key={String(props.item.value)}
                value={String(props.item.value)}
                disabled={props.disabled}
                selected={props.withCheckbox ? false : props.selected}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                sx={{
                    paddingTop: 0,
                    paddingBottom: 0,
                }}
                {...props.attrs}
            >
                <Box
                    sx={{
                        width: '100%',
                        paddingTop: 1,
                        paddingBottom: 1,
                        paddingRight: 2,
                        fontSize: theme.typography.subtitle1,
                        display: 'flex',
                        alignItems: 'center',
                        ...props.sx,
                    }}
                    component="div"
                    onClick={onSelect}
                >
                    {props.withCheckbox && (
                        <Checkbox
                            sx={{ padding: 0, marginRight: 1 }}
                            size={props.size}
                            checked={props.selected}
                            disabled={props.disabled}
                        />
                    )}
                    {props.item.name}
                </Box>
                {props.withCheckbox && (
                    <Box onClick={onClick} component="span" sx={{ width: '2em' }}>
                        {isHovered && (props.showOnly ?? true) && (
                            <SelectOnlyTypography variant="subtitle2">Only</SelectOnlyTypography>
                        )}
                    </Box>
                )}
            </MenuItem>
        );
    },
);

type GroupProps<T> = {
    group: Group;
    selected: Map<T, Option<T>>;
    options: Option<T>[];
    disabled?: boolean;
    multiple?: boolean;
    onSelect?: (group: Group, opt: Option<T>) => void;
    onGroupSelect?: (group: Group, opt: Option<T>[]) => void;
    onClick?: (group: Group, opt: Option<T>) => void;
};

const OptionsGroup = typedMemo(<T extends OT | OT[]>(props: GroupProps<T>) => {
    const theme = useTheme();

    const count = useMemo(
        () => props.options.filter((opt) => props.selected.has(opt.value)).length,
        [props.selected, props.options],
    );

    const onGroupSelect = useCallback(() => {
        if (count === 0) {
            props.onGroupSelect?.(props.group, props.options);
        } else {
            props.onGroupSelect?.(props.group, []);
        }
    }, [props.group, props.options, props.onGroupSelect]);

    return (
        <>
            <ListSubheader sx={{ fontSize: theme.typography.subtitle1, lineHeight: 2 }}>
                {props.multiple && (
                    <Checkbox
                        sx={{ p: 0 }}
                        indeterminate={count > 0 && count < props.options.length}
                        checked={Boolean(count && count === props.selected.size)}
                        disabled={props.disabled}
                        onClick={onGroupSelect}
                    />
                )}{' '}
                {props.group.name}
            </ListSubheader>
            {props.options.map((option) => (
                <CheckMenuItem
                    key={String(option.value)}
                    item={option}
                    withCheckbox={props.multiple}
                    size="small"
                    sx={{
                        fontSize: theme.typography.body2,
                        paddingLeft: 2,
                        paddingTop: 0.5,
                        paddingBottom: 0.5,
                    }}
                    selected={props.selected.has(option.value)}
                    disabled={props.disabled}
                    onSelect={(opt) => props.onSelect?.(props.group, opt)}
                    showOnly={props.multiple}
                    onClick={(opt) => props.onClick?.(props.group, opt)}
                />
            ))}
        </>
    );
});

type MenuListProps<T> = {
    selectedOptions: Option<T>[];
    options: Option<T>[];
    multiple?: boolean;
    multiGroup?: boolean;
    grouped?: boolean;
    neitherable?: boolean;
    withAll?: boolean;
    disabled?: boolean;
    open: boolean;
    searchable?: boolean;
    searchHint?: string;
    searchMore?: (searchTerm: string) => void;
    onSelect: (options: Nullable<Option<T>[]>, group?: string) => void;
    loadMore?: () => void;
};

export const MenuList = typedMemo(
    <T extends OT | OT[]>(
        props: MenuListProps<T> & {
            initOptsCount?: number;
        },
    ) => {
        const initOptsCount = props.initOptsCount || 50;
        const selected = useMemo(
            () => new Map(props.selectedOptions.map((opt) => [opt.value, opt])),
            [props.selectedOptions],
        );
        const { searchActive, searchTerm, searchResults, searchMenuItem } = useMenuSearch(
            props.options,
            !props.searchable,
            props.searchHint,
            props.disabled,
        );
        const [, startTransition] = useTransition();

        const options = searchActive ? searchResults : props.options;

        const [displayOptions, setDisplayOptions] = useState<Option<T>[]>(options.slice(0, initOptsCount));

        const timeout = useRef<number>();
        useEffect(() => {
            clearTimeout(timeout.current);
            if (props.open) {
                timeout.current = window.setTimeout(() => setDisplayOptions(options), 500);
            } else {
                startTransition(() => setDisplayOptions(options.slice(0, initOptsCount)));
            }
            return () => clearTimeout(timeout.current);
        }, [options, props.open]);

        const [groupId, setGroupId] = useState<string>(props.selectedOptions[0]?.group?.id || '');
        const groupById = useMemo(
            () =>
                props.grouped
                    ? displayOptions.reduce<Record<string, [string, Option<T>[]]>>((acc, opt) => {
                          const groupId = opt.group?.id || '';
                          const groupName = opt.group?.name || 'others';
                          acc[groupId] = acc[groupId] || [groupName, []];
                          acc[groupId][1].push(opt);
                          return acc;
                      }, {})
                    : {},
            [displayOptions, props.grouped],
        );
        const groups = useMemo(() => {
            const entries = Object.entries(groupById);
            return entries.map(([id, [name, options]]) => [{ id, name } as Group, options] as const);
        }, [groupById]);

        const showGroups = props.grouped && groups.length >= 1;

        const onItemSelect = useCallback(
            (opt: Option<T>, group?: string) => {
                const map = new Map(selected);
                if (map.has(opt.value)) {
                    map.delete(opt.value);
                } else {
                    map.set(opt.value, opt);
                }
                props.onSelect(Array.from(map.values()), group);
            },
            [selected, props.onSelect],
        );

        const onItemClick = useCallback(
            (opt: Option<T>, group?: string) => {
                props.onSelect([opt], group);
            },
            [selected, props.onSelect],
        );

        const onClickInGroup = useCallback(
            (group: Group, opt: Option<T>) => {
                onItemClick(opt, group.name);
            },
            [onItemClick],
        );

        const onAllSelect = useCallback(() => {
            if (selected.size === displayOptions.length) {
                props.onSelect([]);
            } else {
                props.onSelect(displayOptions);
            }
        }, [displayOptions, props.onSelect, selected]);

        const onSelectInGroup = useCallback(
            (group: Group, opt: Option<T>) => {
                if (props.multiple && (groupId === group.id || props.multiGroup)) {
                    onItemSelect(opt, group.name);
                } else {
                    setGroupId(group.id);
                    onItemClick(opt, group.name);
                }
            },
            [groupId, props.multiple, onItemClick, onItemSelect],
        );

        const onGroupSelect = useCallback(
            (group: Group, opts: Option<T>[]) => {
                if (props.multiGroup) {
                    opts = [...opts, ...props.selectedOptions.filter((opt) => opt.group?.id !== group.id)];
                }

                props.onSelect(opts, group.name);
            },
            [props.onSelect],
        );

        return (
            <>
                {props.searchable && searchMenuItem}
                {props.neitherable && (
                    <MenuItem
                        disabled={props.disabled}
                        id="neither-menu-item"
                        value="neither-menu-item"
                        onClick={() => props.onSelect(null)}
                        sx={{
                            paddingTop: 1.5,
                            paddingBottom: 1.5,
                        }}
                    >
                        <ListItemText primary="Neither" sx={{ fontStyle: 'italic' }} />
                    </MenuItem>
                )}
                {props.withAll && (
                    <MenuItem
                        id="select-all-menu-item"
                        value="select-all-menu-item"
                        onClick={onAllSelect}
                        disabled={props.disabled}
                        sx={{
                            paddingTop: 0,
                            paddingBottom: 0,
                        }}
                    >
                        <Checkbox
                            sx={{ pl: 0 }}
                            checked={selected.size === displayOptions.length}
                            disabled={props.disabled}
                        />
                        <ListItemText primary="All" />
                    </MenuItem>
                )}
                {showGroups &&
                    groups.map(([group, groupOptions]) => {
                        if (
                            groupOptions.length === 1 &&
                            Array.isArray(groupOptions[0].value) &&
                            groupOptions[0].value.length === 0
                        ) {
                            return (
                                <CheckMenuItem
                                    key={`org-selector-menu-item-${group.id}`}
                                    item={groupOptions[0]}
                                    selected={false}
                                    withCheckbox={false}
                                    disabled={props.disabled}
                                    onSelect={props.multiple ? onItemSelect : onItemClick}
                                    onClick={onItemClick}
                                />
                            );
                        }
                        const selectedGroupOptions = new Map(
                            Array.from(selected).filter(
                                ([_, val]) => val.group === undefined || val.group?.id === group.id,
                            ),
                        );

                        return (
                            <OptionsGroup
                                key={group.id}
                                group={group}
                                options={groupOptions}
                                selected={selectedGroupOptions}
                                disabled={props.disabled}
                                multiple={props.multiple}
                                onSelect={onSelectInGroup}
                                onGroupSelect={onGroupSelect}
                                onClick={onClickInGroup}
                            />
                        );
                    })}
                {!showGroups &&
                    displayOptions.map((opt) => (
                        <CheckMenuItem
                            key={`org-selector-menu-item-${opt.value}`}
                            item={opt}
                            selected={selected.has(opt.value)}
                            withCheckbox={props.multiple || Boolean(props.grouped)}
                            disabled={props.disabled}
                            onSelect={props.multiple ? onItemSelect : onItemClick}
                            onClick={onItemClick}
                        />
                    ))}
                {props.searchMore && searchTerm && searchResults.length === 0 ? (
                    <MenuItem
                        id="search-button-menu-item"
                        value="search-button-menu-item"
                        onClick={() => props.searchMore && props.searchMore(searchTerm)}
                        disabled={props.disabled}
                        sx={{
                            paddingTop: 1.5,
                            paddingBottom: 1.5,
                        }}
                    >
                        <ListItemButtonLabel primary="Click here to search" />
                    </MenuItem>
                ) : (
                    props.loadMore && (
                        <MenuItem
                            id="load-more-menu-item"
                            value="load-more-menu-item"
                            onClick={props.loadMore}
                            disabled={props.disabled}
                            sx={{
                                paddingTop: 1.5,
                                paddingBottom: 1.5,
                            }}
                        >
                            <ListItemButtonLabel primary="Load more..." />
                        </MenuItem>
                    )
                )}
            </>
        );
    },
);

export type MultiSelectProps<T> = SelectProps & {
    id?: string;
    sx?: SelectProps['sx'];
    defaultValue?: string;
    disabled?: boolean;
    value: Option<T>[];
    options: Option<T>[];
    neitherable?: boolean;
    isError?: boolean;
    multiple?: boolean;
    multiGroup?: boolean;
    label?: string;
    placeholder?: string;
    className?: string;
    classes?: Partial<SelectClasses>;
    input?: React.ReactElement;
    withAll?: boolean;
    grouped?: boolean;
    searchable?: boolean;
    searchHint?: string;
    searchMore?: (searchTerm: string) => void;
    loading?: boolean;
    disablePortal?: boolean;
    hideArrow?: boolean;
    renderValue?: () => React.ReactNode | undefined;
    onOptsSelect?: (options: Nullable<Option<T>[]>, group?: string) => void;
    onClose?: () => void;
    loadMore?: () => void;
};

export const MultiSelect = typedMemo(
    typedForwardRef(<T extends OT | OT[]>(props: MultiSelectProps<T>, fwdRef: ForwardedRef<unknown>) => {
        const theme = useTheme();
        const [open, setOpen] = useState(false);
        const [menuWidth, setMenuWidth] = useState(0);
        const ref = useRef<Element>(null);
        const isHovering = useHoverDirty(ref);

        useImperativeHandle(fwdRef, () => ref.current);

        const onSelect = useCallback(
            (options: Nullable<Option<T>[]>, group?: string) => {
                props.onOptsSelect?.(options, group);
                // Closing when it's a single selected item mode or special value (neither)
                if (!props.multiple || options === null) {
                    setOpen(false);
                }
            },
            [props.multiple, props.grouped, props.onClose, props.onOptsSelect],
        );

        useEffect(() => {
            setOpen(props.open || false);
        }, [props.open]);

        const {
            value,
            multiple,
            multiGroup,
            isError,
            onOptsSelect,
            withAll,
            grouped,
            loading,
            searchable,
            searchHint,
            neitherable,
            options,
            hideArrow,
            disablePortal,
            disableUnderline,
            loadMore,
            searchMore,
            ...rest
        } = props;

        const finalDisabled = props.isError || props.disabled || props.loading;

        const InputComponent =
            props.input ||
            (props.variant === 'standard' ? (
                <Input
                    disableUnderline={props.disableUnderline ?? true}
                    sx={{ [`& .${selectClasses.select}`]: { pt: 0 } }}
                />
            ) : undefined);

        const renderValue = useCallback(() => {
            const customValue = props.renderValue?.();
            if (customValue) {
                return customValue;
            }
            const noData = !props.options.length;
            if (props.isError) return <i>Error</i>;
            if (noData) return <i>No data</i>;
            if (!props.value.length) return props.placeholder ? <i>{props.placeholder}</i> : '';

            return props.value.length === props.options.length && props.withAll ? (
                <i>All</i>
            ) : (
                props.value.map((opt) => opt.name).join(', ')
            );
        }, [loading, props.placeholder, props.value, props.renderValue, props.options]);

        const onOpen = useCallback(
            (event: React.SyntheticEvent<Element, Event>) => {
                setOpen(true);
                props.onOpen?.(event);
            },
            [props.onOpen],
        );

        const onClose = useCallback(() => {
            setOpen(false);
            props.onClose?.();
        }, [props.onClose]);

        return (
            <StyledSelect
                {...rest}
                ref={ref}
                sx={{
                    [`& .${selectClasses.outlined}.${selectClasses.disabled}`]: {
                        textFillColor: 'currentColor',
                    },
                    [`& .${selectClasses.outlined}`]: !props.value.length && {
                        textFillColor: alpha(theme.palette.text.primary, 0.45),
                    },
                    ...props.sx,
                    [`& .${selectClasses.iconOutlined}`]: { display: loading ? 'none' : 'inline' },
                    [`& .${selectClasses.select}:focus`]: { backgroundColor: 'transparent' },
                    [`& .${selectClasses.select}`]: {
                        paddingBottom: props.variant === 'standard' ? 0 : 1,
                    },
                    [`& .${selectClasses.select}.MuiInputBase-input`]: {
                        paddingRight: '0',
                    },
                }}
                id={props.id}
                size="small"
                disabled={finalDisabled}
                displayEmpty
                onOpen={onOpen}
                onClose={onClose}
                open={open}
                placeholder={props.placeholder}
                variant={props.variant || 'outlined'}
                input={InputComponent}
                multiple={multiple}
                label={props.label}
                renderValue={renderValue}
                className={props.className}
                classes={props.classes}
                inputProps={{
                    value: props.value.length
                        ? props.value.map((opt) => opt.value)
                        : renderValue()
                          ? [renderValue()]
                          : [],
                    multiple: true,
                    label: props.label,
                }}
                IconComponent={() =>
                    (props.disabled && hideArrow) || props.loading ? null : open ? (
                        <ArrowDropUpIcon color="action" />
                    ) : (
                        <ArrowDropDownIcon
                            color="action"
                            sx={{
                                visibility: hideArrow ? (isHovering ? 'visible' : 'hidden') : 'visible',
                            }}
                        />
                    )
                }
                MenuProps={{
                    ...props.MenuProps,
                    disablePortal: props.disablePortal,
                    PaperProps: {
                        sx: {
                            maxHeight: '40vh',
                            height: 'fit-content',
                            width: `${menuWidth}px`,
                        },
                    },
                    MenuListProps: {
                        ref: (menuList) => setMenuWidth((width) => Math.max(width, menuList?.clientWidth || 0)),
                        sx: { paddingTop: 0, minWidth: 'fit-content' },
                    },
                }}
            >
                {loading && (
                    <Box
                        sx={{
                            position: 'absolute',
                            display: 'flex',
                            zIndex: 100,
                            width: '100%',
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: '#fff5',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
                <MenuList
                    multiple={props.multiple}
                    multiGroup={props.multiGroup}
                    disabled={finalDisabled}
                    options={props.options}
                    selectedOptions={props.value}
                    grouped={props.grouped}
                    withAll={props.withAll}
                    neitherable={props.neitherable}
                    open={open}
                    searchable={searchable}
                    searchHint={searchHint}
                    onSelect={onSelect}
                    loadMore={
                        loadMore
                            ? () => {
                                  setOpen(false);
                                  loadMore?.();
                              }
                            : undefined
                    }
                    searchMore={
                        searchMore
                            ? (searchTerm) => {
                                  setOpen(false);
                                  searchMore?.(searchTerm);
                              }
                            : undefined
                    }
                />
            </StyledSelect>
        );
    }),
);

export default MultiSelect;
