import { useMemo } from 'react';
import axios, { InternalAxiosRequestConfig } from 'axios';
import { Auth } from 'aws-amplify';
import qs from 'qs';
import { datadogLogs } from '@datadog/browser-logs';

import {
    ApiError,
    ArgumentUsedError,
    NonEmptyItemOverwrite,
    NoPolicyError,
    SchemaError,
    StaleTicketUpdate,
    TemplateError,
    TicketIsLockedError,
} from './error';

const env = import.meta.env.VITE_DEPLOY_ENV;

// Should be used outside React context only (before app is initialized). Use `useApi` hook when possible.
export const api = buildApi();

export const useApi = () => {
    return useMemo(buildApi, []);
};

function buildApi() {
    const instance = axios.create({
        baseURL: import.meta.env.VITE_API_URL,
        paramsSerializer(params) {
            return qs.stringify(params, { indices: false, arrayFormat: 'repeat' });
        },
    });

    axios.interceptors.request.use((request) => {
        datadogLogs.logger.info(`API request ${request.url}`, { request: request, env: env.toLowerCase() });
        return request;
    });

    instance.interceptors.request.use(async function (config: InternalAxiosRequestConfig) {
        if (!('Authorization' in config.headers)) {
            config.headers.Authorization = await getJwtToken();
        }
        return config;
    });

    instance.interceptors.response.use(
        (response) => {
            datadogLogs.logger.info(`API response`, { response: response, env: env.toLowerCase() });
            const data = response.data;
            return data && Object.prototype.hasOwnProperty.call(data, 'results') ? data.results : data;
        },
        async (error: any) => {
            datadogLogs.logger.info('API Error', error);
            if (error.response?.status === 422) {
                throw new SchemaError('Bad schema', error.response.data);
            }

            if (error.response?.status >= 400 && error.response?.data) {
                switch (error.response.data.code) {
                    case 'NO_POLICY':
                        throw new NoPolicyError(error.response.data.detail);
                    case 'STALE_TICKET_UPDATE':
                        throw new StaleTicketUpdate(error.response.data.detail);
                    case 'ARGUMENT_METADATA_IN_USE':
                        throw new ArgumentUsedError('Argument metadata is being used', error.response.data.detail);
                    case 'TEMPLATE_ERROR':
                        throw new TemplateError('Template error', error.response.data.detail);
                    case 'NON_EMPTY_ITEM_OVERWRITE':
                        throw new NonEmptyItemOverwrite(error.response.data.detail);
                    case 'TICKET_IS_LOCKED':
                        throw new TicketIsLockedError(error.response.data.detail);
                    default:
                        throw new ApiError(error.response.data.code, 'Backend error', error.response.data.detail);
                }
            }
            throw error;
        },
    );

    return instance;
}

async function getJwtToken() {
    try {
        const currentSession = await Auth.currentSession();
        return currentSession.getIdToken().getJwtToken();
    } catch {
        return Promise.reject(() => window.location.reload());
    }
}
