import { FunctionComponent, ReactNode, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
    Box,
    FormControl,
    Grid,
    IconButton,
    outlinedInputClasses,
    styled,
    TextField,
    TextFieldProps,
    Theme,
    Tooltip,
    Typography,
} from '@mui/material';
import { SxProps } from '@mui/system';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import { typedMemo } from '@global/types';
import { CopyToClipboardButton, MultiSelect, MultiSelectProps } from '@tymely/components';
import { IArgument } from '@tymely/atoms';

export interface ArgumentFieldProps<T extends IArgument> {
    argument: T;
    disabled?: boolean;
    withLabel?: boolean;
    loading?: boolean;
    sx?: SxProps<Theme>;
    onChange?: (argument: IArgument[], intermediate?: boolean) => Promise<IArgument[]>;
    setHighlightText?: (value: string) => void;
}

export const ArgNameTypography = styled(Typography)(() => ({
    height: '100%',
    overflow: 'auto',
    textAlign: 'left',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
}));

const borderStyle = (theme: Theme, outline?: boolean) => ({
    borderColor: outline ? theme.palette.warning.main : null,
    borderWidth: outline ? 2 : 1,
});

const inputStyle = (theme: Theme, outline?: boolean) => ({
    [`.${outlinedInputClasses.notchedOutline}`]: borderStyle(theme, outline),
    [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: borderStyle(theme, outline),
    [`&:hover .${outlinedInputClasses.notchedOutline}`]: borderStyle(theme, outline),
});

export const StyledOutlinedInput = styled(
    (props) => {
        const inputProps: TextFieldProps = props;
        // Beware `rows` must be set if `multiline=true` (https://stackoverflow.com/a/72789474)
        return (
            <TextField
                size="small"
                multiline={inputProps.multiline}
                rows={inputProps.multiline ? 2 : undefined}
                sx={{ width: '100%' }}
                variant="outlined"
                {...inputProps}
            />
        );
    },
    {
        shouldForwardProp: (prop) => !['edited', 'specialValue'].includes(String(prop)),
    },
)<TextFieldProps & { edited?: boolean; specialValue?: IArgument['special_value'] }>(
    ({ theme, value, disabled, edited, specialValue, onChange }) => ({
        ...inputStyle(theme, (value === '' || value === null) && !specialValue && !disabled && !edited && !!onChange),
    }),
) as FunctionComponent<TextFieldProps & { edited?: boolean; specialValue?: IArgument['special_value'] }>;
StyledOutlinedInput.displayName = 'StyledOutlinedInput';

type ArgMultiSelectProps<T> = MultiSelectProps<T> & {
    specialValue: IArgument['special_value'];
    edited?: boolean;
};

const TypedMultiSelect = <T extends boolean | string | string[]>({
    specialValue,
    neitherable,
    searchable = true,
    ...props
}: ArgMultiSelectProps<T>) => {
    return (
        <MultiSelect
            {...props}
            neitherable={neitherable}
            options={props.options || []}
            searchable={searchable}
            renderValue={() => {
                const noOptions = !props.options.length;
                if (specialValue === 'unspecified') return <i>Unspecified</i>;
                if (specialValue === 'unknown') return <i>Unknown to customer</i>;
                if (specialValue === 'neither') return <i>Neither</i>;
                if (noOptions) return <i>{props.searchMore ? '' : 'No data'}</i>;
                return;
            }}
        />
    );
};

export const StyledMultiSelect = styled(TypedMultiSelect, {
    shouldForwardProp: (prop) => !['edited'].includes(String(prop)),
})(({ theme, value, options, disabled, edited, specialValue }: ArgMultiSelectProps<unknown> & { theme: Theme }) => ({
    ...inputStyle(theme, !value?.length && !specialValue && !!options.length && !disabled && !edited),
})) as typeof TypedMultiSelect;
(StyledMultiSelect as any).displayName = 'StyledMultiSelect';

const StyledIconButton = styled(IconButton)(() => ({
    padding: 0,
    '&:hover': { backgroundColor: 'transparent' },
}));

const StyledError = styled(StyledOutlinedInput)(({ theme }) => ({
    [`.${outlinedInputClasses.input}`]: {
        color: theme.palette.error.light,
    },
    ...inputStyle(theme, true),
}));

export const ArgErrorFallback = ({ error }: { error: Error }) => {
    const value = `Error: ${(error as Error).message}`;
    return (
        <Tooltip title={(error as Error).message}>
            <Box width={1}>
                <StyledError error multiline={false} value={value} inputProps={{ readOnly: true }} />
            </Box>
        </Tooltip>
    );
};

export const Argument = typedMemo(
    <T extends IArgument>(props: {
        argument: T;
        withCopy?: boolean;
        mutable?: boolean;
        disabled?: boolean;
        className: string;
        children: ReactNode;
        onTitleClick?: () => void;
        onSetUnspecified?: (argId: T['id']) => void;
        onSetUnknown?: (argId: T['id']) => void;
    }) => {
        const ref = useRef<HTMLDivElement | null>(null);

        return (
            <Grid container ref={ref} onClick={(e) => e.stopPropagation()}>
                <Grid item xs={12} md={12} mb={1} display="flex" alignItems="center">
                    <Tooltip title={props.argument.description || ''} enterDelay={1000} followCursor>
                        <ArgNameTypography
                            variant="body1"
                            sx={{ m: 0, mr: 1, height: 'auto', cursor: props.onTitleClick ? 'pointer' : 'auto' }}
                            onClick={props.onTitleClick}
                        >
                            {props.argument.title}
                        </ArgNameTypography>
                    </Tooltip>
                    {props.argument.icon && (
                        <Box
                            component="img"
                            src={`/assets/integrations/${props.argument.icon}.png`}
                            sx={{ width: 32, mr: 1 }}
                        />
                    )}
                </Grid>
                <Grid item xs={12} md={12} mb={1} sx={{ width: 0 }}>
                    <FormControl
                        fullWidth
                        sx={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            pl: 0,
                        }}
                    >
                        <Box sx={{ flex: 1, overflow: 'hidden' }}>
                            <ErrorBoundary FallbackComponent={ArgErrorFallback}>{props.children}</ErrorBoundary>
                        </Box>
                        {!props.disabled && props.mutable && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    ml: 1,
                                }}
                            >
                                {props.withCopy && (
                                    <CopyToClipboardButton text={JSON.stringify(props.argument.value)} size="small" />
                                )}
                                <Box display="inline-block">
                                    {props.onSetUnspecified && (
                                        <Tooltip title="Unspecified" enterDelay={0.5}>
                                            <Box>
                                                <StyledIconButton
                                                    aria-label="unspecified"
                                                    onClick={() => props.onSetUnspecified?.(props.argument.id)}
                                                    disabled={
                                                        props.disabled ||
                                                        (props.argument.special_value === 'unspecified' &&
                                                            props.argument.value === null) ||
                                                        !props.argument.unspecifiable
                                                    }
                                                >
                                                    {props.argument.special_value === 'unspecified' ? (
                                                        <RadioButtonCheckedIcon fontSize="small" />
                                                    ) : (
                                                        <RadioButtonUncheckedIcon fontSize="small" />
                                                    )}
                                                </StyledIconButton>
                                            </Box>
                                        </Tooltip>
                                    )}
                                    {props.onSetUnknown && props.argument.unknowable && (
                                        <Tooltip title="Unknown to customer" enterDelay={0.5}>
                                            <Box>
                                                <StyledIconButton
                                                    aria-label="unknown"
                                                    onClick={() => props.onSetUnknown?.(props.argument.id)}
                                                    disabled={
                                                        props.disabled ||
                                                        props.argument.special_value === 'unknown' ||
                                                        !props.argument.unknowable
                                                    }
                                                >
                                                    <PsychologyAltIcon />
                                                </StyledIconButton>
                                            </Box>
                                        </Tooltip>
                                    )}
                                </Box>
                            </Box>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
        );
    },
);
