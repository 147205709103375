import { Box, Button, styled, Typography } from '@mui/material';
import { useUser } from '@tymely/services';

import useArgumentsQuery from './useArgumentsQuery';

const _Root = styled(Box)`
    padding: ${({ theme }) => theme.spacing(2, 2, 0)};
    display: flex;
`;

const _Label = styled(Typography)`
    font-weight: 500;
    padding: 0;
    margin-right: ${({ theme }) => theme.spacing(1)};
    display: inline;
`;

type ArgumentsActionsProps = {
    version?: string;
    onDependencyGraph: () => void;
};

const ArgumentsActions = ({ version, onDependencyGraph }: ArgumentsActionsProps) => {
    const user = useUser();
    const argsQuery = useArgumentsQuery({ version });

    return (
        <_Root>
            <Box sx={{ flex: 1, position: 'relative' }}>
                <_Label variant="h5">Arguments</_Label>
                {user?.isAdmin && (
                    <Button disabled={!argsQuery.data} onClick={onDependencyGraph}>
                        Dependency graph
                    </Button>
                )}
            </Box>
        </_Root>
    );
};

export default ArgumentsActions;
