import { applyArgUpdates } from '@tymely/atoms';
import React from 'react';
import { Loadable } from '@tymely/components';
import { AppMode, useAppMode, useTicketLoadingStatus } from '@tymely/services';

import ArgumentList from './ArgumentList';
import useArgumentsQuery from './useArgumentsQuery';
import { useArgumentsTabsContext } from './ArgumentsTabsProvider';

const SystemArgumentSection = () => {
    const { appMode } = useAppMode();
    const { version, updates } = useArgumentsTabsContext();

    const argsQuery = useArgumentsQuery({ version });

    const args = React.useMemo(() => {
        if (!argsQuery.data) {
            return [];
        }
        return applyArgUpdates(argsQuery.data, updates).filter(({ arg_type }) => arg_type === 'SYSTEM_ARGUMENT');
    }, [argsQuery.data, updates]);

    const loadingStatus = useTicketLoadingStatus();

    return (
        <Loadable waitFor={args}>
            <ArgumentList argumentItems={args} withCopy disabled={appMode !== AppMode.Analyst || !!loadingStatus} />
        </Loadable>
    );
};

export default SystemArgumentSection;
