import { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { IDecision, ITicket } from '@tymely/atoms';
import { useApi } from '@tymely/api';

import { useSelectedComment, _useUpdateCommentCached, AGENT_RESPONSE_QUERY_KEY } from './comment.services';
import { useIntentsQuery } from './intent.services';
import { useTicketFinalizeSubmit, useTicket } from './ticket.services';
import { useSetTicket } from './ticket.services';
import { AppMode } from './mode';

const highlights897871 = [
    { token: '\\*\\*Tymely test, please ignore\\*\\*\n\n', replace: '' },
    { token: 'Revital', replace: 'James', class: 'highlight' },
    { token: 'revitul16@gmail.com', replace: 'James.brown100300@gmail.com' },
    {
        token: 'Legend',
        class: 'highlight',
        bubble: {
            title: 'Model',
            offset: [-70, 10],
            placement: 'top',
            options: [],
            startArrowPlacement: 'bottom',
            endArrowPlacement: 'top',
        },
    },
    {
        token: 'Black Matte',
        class: 'highlight',
        bubble: {
            title: 'Color',
            offset: [0, 20],
            placement: 'top',
            options: ['Gray', 'Black', 'Blue', 'Brown'],
            startArrowPlacement: 'bottom',
            endArrowPlacement: 'top',
        },
    },
    {
        token: 'boots',
        class: 'highlight',
        bubble: {
            title: 'Item type',
            offset: [80, 10],
            placement: 'top',
            options: [],
            startArrowPlacement: 'bottom',
            endArrowPlacement: 'top',
        },
    },
    {
        token: 'size that is too small',
        class: 'highlight',
    },
    {
        token: 'size that',
        bubble: {
            title: 'Reason for exchange',
            offset: [-50, 0],
            placement: 'left',
            options: [],
            startArrowPlacement: 'bottom',
            endArrowPlacement: 'top',
        },
    },
    {
        token: 'want to<br>exchange',
        class: 'highlight',
    },
    {
        token: 'want to',
        bubble: {
            title: 'Requested action',
            offset: [0, -50],
            placement: 'right',
            options: [],
            startArrowPlacement: 'left',
            endArrowPlacement: 'right',
        },
    },
    {
        token: 'half a size bigger',
        class: 'highlight',
    },
    {
        token: 'half a',
        bubble: {
            title: 'Requested exchange',
            offset: [-15, 0],
            placement: 'left',
            options: [],
            startArrowPlacement: 'right',
            endArrowPlacement: 'top',
        },
    },
    {
        token: '46556',
        class: 'highlight',
        bubble: {
            title: 'Order number',
            offset: [20, 0],
            placement: 'left',
            options: ['Order number', 'Zip code', 'Item ID'],
            startArrowPlacement: 'right',
            endArrowPlacement: 'left',
        },
    },
    {
        token: 'will not be back<br>till January 15th',
        class: 'highlight',
    },
    {
        token: 'will not be',
        bubble: {
            title: 'Time constrains',
            offset: [50, -100],
            placement: 'right',
            options: [],
            startArrowPlacement: 'top',
            endArrowPlacement: 'bottom',
        },
    },
];

const highlights670310 = [
    {
        token: 'my husband',
        class: 'highlight',
        bubble: {
            title: 'Recipient',
            offset: [-100, 20],
            placement: 'top',
            options: [],
            startArrowPlacement: 'bottom',
            endArrowPlacement: 'top',
        },
    },
    {
        token: 'Reed',
        class: 'highlight',
        bubble: {
            title: 'Color',
            offset: [-80, 20],
            placement: 'top',
            options: ['Color', 'Model', 'Size'],
            startArrowPlacement: 'bottom',
            endArrowPlacement: 'top',
        },
    },
    {
        token: 'boots',
        class: 'highlight',
        bubble: {
            title: 'Item type',
            offset: [20, 20],
            placement: 'top',
            options: [],
            startArrowPlacement: 'bottom',
            endArrowPlacement: 'top',
        },
    },
    {
        token: 'birthday',
        class: 'highlight',
        bubble: {
            title: 'Special Occasion',
            offset: [60, 20],
            placement: 'top',
            options: [],
            startArrowPlacement: 'bottom',
            endArrowPlacement: 'top',
        },
    },
    {
        token: 'wore them',
        class: 'highlight',
        bubble: {
            title: 'Item condition',
            offset: [-20, 0],
            placement: 'left',
            options: [],
            startArrowPlacement: 'right',
            endArrowPlacement: 'top',
        },
    },
    {
        token: 'too small',
        class: 'highlight',
        bubble: {
            title: 'Reason for Exchange',
            offset: [20, 0],
            placement: 'left',
            options: [],
            startArrowPlacement: 'left',
            endArrowPlacement: 'bottom',
        },
    },
    {
        token: 'exchange',
        class: 'highlight',
        bubble: {
            title: 'Requested Action',
            offset: [90, 0],
            placement: 'left',
            options: [],
            startArrowPlacement: 'right',
            endArrowPlacement: 'bottom',
        },
    },
    {
        token: 'half a size bigger',
        class: 'highlight',
        bubble: {
            title: 'Requested Size',
            offset: [30, -30],
            placement: 'right',
            options: [],
            startArrowPlacement: 'left',
            endArrowPlacement: 'bottom',
        },
    },
];

const demoTicketData = [
    {
        id: 897871,
        comment: {
            from_name: 'James Connel',
            from_email: 'JamesConnel@gmail.com',
            body: `
I recently bought some Legend Black Matte boots. I love the style and
the boots, but I ordered a size that is too small for me. I want to
exchange them for half a size bigger. The zip code used for the order is
46556. How should I proceed with the exchange? I am asking given that
I am a college student leaving for Christmas break and will not be back
till January 15th. Please let me know how to proceed.
Thank you.`,
            selected_intent_id: 428,
            additional_data: {
                highlights: highlights897871,
                country_code: 'US',
                issue_type: 'Item exchange',
            },
            response_body: `Hey James,
I'm sorry to hear the size of the boots did not work out for you.
I've gone ahead and started the exchange process for new Legend | Black Matte boots in a size 9.
Please see the UPS shipping label here: https://www.apps.ups.com/tracking/tracking.cgi?tracknum=34738412383
And no worries, the delivery will only arrive after January 15th.
Please let us know if we can help with anything else!
Mindee.
`,
        },
        additional_data: {
            explanations: [
                {
                    wf_title: '/assets/demo/shopify.jpg',
                    did_hit: true,
                    wf_id: 1,
                    condition_explanations: ['Order # found: **32133**'],
                },
                {
                    wf_title: '/assets/demo/shopify.jpg',
                    did_hit: true,
                    wf_id: 1,
                    condition_explanations: ['Requested exchange size: **9**'],
                },
                {
                    wf_title: '/assets/demo/channel_ape.jpg',
                    did_hit: true,
                    wf_id: 1,
                    condition_explanations: ['Size 9: **exists**'],
                },
            ],
            actions: [
                {
                    description: '**Exchange** Legend Boots size 8.5 &#8594; 9',
                    title: '/assets/demo/loop.jpg',
                },
                {
                    description: 'Issue a **return label**',
                    executor_name: 'respond',
                    title: '/assets/demo/happy.jpg',
                },
            ],
            highlight_spans: [
                [4, 9],
                [33, 37],
                [45, 50],
                [97, 125],
                [134, 154],
                [166, 172],
                [189, 192],
                [214, 282],
            ] as [start: number, end: number][],
        },
    },
    {
        id: 670310,
        comment: {
            body: `
I bought my husband a pair of Reed boots for his birthday, he
wore them for a few hours to see how they felt and he said that they
were too small. I would like to exchange them for half a size bigger please!
`,
            selected_intent_id: 428,
            additional_data: {
                highlights: highlights670310,
                country_code: 'US',
                issue_type: 'Item exchange',
            },
            response_body: `
Hey Jane,

I'm sorry to hear the size of the boots from order #24345 did not work out for your husband. I've gone ahead and started the exchange process for new Reed boots in a size 9. Please see the Happy Returns shipping label <a href="https://static.returngo.ai/prod-pub-uploads/labels/65529aab0b30b984d8bd6f1dc5d5fae6.png">here</a>.

Please let us know if we can help with anything else! Mindee.
`,
        },
        additional_data: {
            explanations: [
                {
                    wf_title: '/assets/demo/shopify.jpg',
                    did_hit: true,
                    wf_id: 1,
                    condition_explanations: ['Searching order with boots &#8594; **Order #24345**'],
                },
                {
                    wf_title: '/assets/demo/shopify.jpg',
                    did_hit: true,
                    wf_id: 1,
                    condition_explanations: [
                        'Ordered boots size &#8594; **8.5** &#8594; Half a size bigger &#8594; **9**',
                    ],
                },
                {
                    wf_title: '/assets/demo/channel_ape.jpg',
                    did_hit: true,
                    wf_id: 1,
                    condition_explanations: ['Checking inventory for size 9 &#8594; **approved**'],
                },
            ],
            actions: [
                {
                    description: '**Exchange** Reed Boots size 8.5 &#8594; 9',
                    title: '/assets/demo/loop.jpg',
                },
                {
                    description: 'Issue a **return label**',
                    executor_name: 'respond',
                    title: '/assets/demo/happy.jpg',
                },
            ],
            highlight_spans: [
                [4, 8],
                [32, 36],
                [44, 49],
                [61, 67],
                [135, 143],
                [160, 170],
                [176, 182],
                [199, 212],
                [234, 321],
            ] as [start: number, end: number][],
        },
    },
];

export const useDemoTicketFetchById = () => {
    const api = useApi();
    const setTicket = useSetTicket();

    return useCallback(
        async (ticketId: number) => {
            let ticket = (await api.get(`ticket/${ticketId}`)) as ITicket;
            const comment = ticket.comments
                .sort(
                    ({ inquiry_date: id1 }, { inquiry_date: id2 }) => new Date(id1).valueOf() - new Date(id2).valueOf(),
                )
                .find(({ is_customer }) => is_customer);

            const demoTicket = demoTicketData.find(({ id }) => id === ticketId);
            const commentData = demoTicket?.comment;
            if (comment) {
                const demoComment = {
                    ...comment,
                    ...commentData,
                    additional_data: {
                        ...comment.additional_data,
                        ...commentData?.additional_data,
                    },
                };
                ticket = {
                    ...ticket,
                    subject: 'Item exchange',
                    comments: ticket.comments
                        .filter(({ is_customer }) => is_customer)
                        .map((it) => (it.id === demoComment.id ? demoComment : it)),
                };
            }

            if (ticket) {
                setTicket(ticket);
            }

            return ticket;
        },
        [setTicket],
    );
};

export const useDemoTicket = () => {
    const api = useApi();
    const ticket = useTicket();
    const selectedComment = useSelectedComment();
    const commentId = selectedComment?.id;
    const updateCommentCached = _useUpdateCommentCached();
    const { data: intents, isFetching } = useIntentsQuery();
    const [decision, setDecision] = useState<{
        explanation: IDecision['explanation'];
        workflow_id: number;
        actions: { description: string; executor_name?: string; title: string }[];
    } | null>(null);
    const ticketFinalizeSubmit = useTicketFinalizeSubmit();

    const queryClient = useQueryClient();

    const updateResponse = (response: string) => {
        queryClient.setQueryData([AGENT_RESPONSE_QUERY_KEY, commentId], response);
    };

    return {
        submit() {
            return ticketFinalizeSubmit(true, false);
        },
        approve() {
            return new Promise<void>((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 500);
            });
        },
        setIntent(intentId: number) {
            if (!commentId) return Promise.reject('No comment selected');

            return api.post(`comment/${commentId}/tag`, undefined, {
                params: {
                    mode: AppMode.Training,
                    policy_set_id: intentId,
                },
            });
        },
        intents: useMemo(
            () =>
                intents?.map((intent) => {
                    if (intent.id === 428) return { ...intent, name: 'I’d like to exchange an item' };
                    if (intent.id === 618) return { ...intent, name: 'How can I return my order?' };
                    return intent;
                }) || [],
            [intents],
        ),
        decision,
        isLoading: isFetching,
        updateResponse,
        loadDecision() {
            if (!commentId) return Promise.reject('No comment selected');

            const demoTicket = demoTicketData.find(({ id }) => id === ticket.id);
            const actions = demoTicket?.additional_data?.actions || [];
            const explanations = demoTicket?.additional_data?.explanations || [];
            const highlight_spans = demoTicket?.additional_data?.highlight_spans || [];
            const responseBody = demoTicket?.comment?.response_body || '';

            setDecision({
                explanation: explanations.slice(0, 1),
                workflow_id: 1,
                actions: [],
            });

            return new Promise<void>((resolve) => {
                let i = 2;
                const intId = setInterval(() => {
                    if (i > explanations.length) {
                        clearInterval(intId);
                        updateResponse(responseBody);
                        updateCommentCached({
                            ...selectedComment,
                            response_body: responseBody,
                            additional_data: {
                                ...selectedComment.additional_data,
                                highlight_spans,
                            },
                        });
                        setDecision({
                            explanation: explanations,
                            workflow_id: 1,
                            actions,
                        });
                        resolve();
                    } else {
                        setDecision({
                            explanation: explanations.slice(0, i++),
                            workflow_id: 1,
                            actions: [],
                        });
                    }
                }, 1000);
            });
        },
    };
};
