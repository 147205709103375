import { useEffect, useMemo, useState } from 'react';
import minBy from 'lodash/minBy';
import partition from 'lodash/partition';
import { PubSub } from 'aws-amplify';
import { IUser } from '@tymely/atoms';
import { useUser } from '@tymely/services';

const ACCESS_EVENT = 'access';
const BROADCAST_INTERVAL = 3000;
const TIMEOUT_INTERVAL = 15000;

type Access = {
    user: IUser;
    at: Date;
    claimLock: boolean;
    claimLockAt: Date;
};

type ConcurrentAccessOptions = {
    claimLock?: boolean;
    onUnlock?: (lockReleased: Lock) => void;
};

export type ConcurrentAccess = {
    viewers: IUser[];
    lock?: Lock;
};

export type Lock = {
    user: IUser;
    lockedAt: Date;
};

export function useConcurrentAccess(
    topic: string | null | undefined | false,
    { claimLock = false, onUnlock }: ConcurrentAccessOptions = {},
): ConcurrentAccess {
    const user = useUser();
    const [prevLock, setPrevLock] = useState<Lock>();
    const claimLockAt = useMemo(() => (claimLock ? new Date() : undefined), [claimLock, topic]);
    const [accessMap, setAccessMap] = useState<Record<IUser['email'], Access>>({});

    useEffect(() => {
        setTimeout(() => {
            setAccessMap({});
            setPrevLock(undefined);
        });
    }, [topic]);

    useEffect(() => {
        if (!topic) return;

        function broadcast() {
            PubSub.publish(topic || '', {
                event: ACCESS_EVENT,
                user,
                claimLock,
                claimLockAt,
            });
        }

        broadcast();
        const broadcastInterval = setInterval(broadcast, BROADCAST_INTERVAL);

        const subscription = PubSub.subscribe(topic).subscribe({
            next(data) {
                if (typeof data.value === 'object' && data.value.event === ACCESS_EVENT) {
                    const event = data.value as Access;
                    setAccessMap((map) => {
                        return {
                            ...map,
                            [event.user.email!]: {
                                claimLockAt: event.claimLockAt,
                                at: new Date(),
                                user: event.user,
                                claimLock: event.claimLock,
                            },
                        };
                    });
                }
            },
        });

        return () => {
            clearInterval(broadcastInterval);
            subscription.unsubscribe();
        };
    }, [topic, claimLock, user]);

    useEffect(() => {
        const interval = setInterval(() => {
            setAccessMap((map) =>
                Object.fromEntries(
                    Object.entries(map).filter(([_, access]) => Date.now() - access.at.getTime() < TIMEOUT_INTERVAL),
                ),
            );
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const [lock, viewers] = useMemo(() => {
        const [lockClaims, viewers] = partition(Object.values(accessMap), (access) => access.claimLock);
        const lockClaim = minBy(lockClaims, 'claimLockAt');
        const lock =
            lockClaim && lockClaim.user.username !== user?.username
                ? {
                      user: lockClaim.user,
                      lockedAt: lockClaim.claimLockAt,
                  }
                : undefined;
        return [lock, viewers];
    }, [accessMap, user]);

    useEffect(() => {
        if (prevLock && !lock) {
            onUnlock?.(prevLock);
        }
        setPrevLock(lock);
    }, [prevLock, lock]);

    return {
        lock,
        viewers: viewers.filter((access) => access.user.email !== user?.email).map((access) => access.user),
    };
}
