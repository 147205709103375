import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { JSONSchema7 } from 'json-schema';
import { IActionMetadata, IArgumentMetadata } from '@tymely/atoms';
import { useApi } from '@tymely/api';
import { AxiosError } from 'axios';

export const ACTIONS_METADATA_QUERY_KEY = 'actionsMetadata';
export const EXECUTOR_SCHEMA_QUERY_KEY = 'executorSchema';
export const ARGS_IN_ACTIONS_QUERY_KEY = 'argsInActions';

export function useActionsMetadataQuery() {
    const api = useApi();
    return useQuery<IActionMetadata[]>({
        queryKey: [ACTIONS_METADATA_QUERY_KEY],
        queryFn: () => api.get('action-metadatas/list', { params: { limit: 1000 } }),
        meta: {
            errorMessage: 'Failed fetching actions metadata.',
        },
    });
}

export function useCreateActionMetadataMutation() {
    const api = useApi();
    const queryClient = useQueryClient();
    return useMutation<IActionMetadata, AxiosError, { metadata: Omit<IActionMetadata, 'id'> }>({
        mutationFn: (params) => api.post('action-metadata', params.metadata),
        onSuccess(data) {
            queryClient.setQueryData([ACTIONS_METADATA_QUERY_KEY], (cache?: IActionMetadata[]) =>
                cache ? cache.concat(data) : [data],
            );
        },
    });
}

export function useEditActionMetadataMutation() {
    const api = useApi();
    const queryClient = useQueryClient();
    return useMutation<IActionMetadata, AxiosError, IActionMetadata>({
        mutationFn: (metadata) => api.put(`action-metadata/${metadata.id}`, metadata),
        onSuccess(data) {
            queryClient.setQueryData<IActionMetadata[] | undefined>(
                [ACTIONS_METADATA_QUERY_KEY],
                (cache?: IActionMetadata[]) => cache?.map((item) => (item.id === data.id ? data : item)),
            );
        },
    });
}

export function useActionMetadataExecutorSchemaQuery(metadataId?: IActionMetadata['id']) {
    const api = useApi();
    return useQuery<JSONSchema7>({
        queryKey: [ACTIONS_METADATA_QUERY_KEY, metadataId, EXECUTOR_SCHEMA_QUERY_KEY],
        queryFn: () => api.get(`action-metadata/${metadataId}/executor-schema`),
        enabled: !!metadataId,
        meta: {
            errorMessage: 'Failed fetching action metadata executor schema.',
        },
    });
}

export function useArgsInActionsQuery() {
    const api = useApi();
    return useQuery<Record<IActionMetadata['id'], IArgumentMetadata['name'][]>, AxiosError>({
        queryKey: [ARGS_IN_ACTIONS_QUERY_KEY],
        queryFn: () => api.get('args-in-actions'),
    });
}
