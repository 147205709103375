import uniqBy from 'lodash/uniqBy';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ISchemaError, SchemaError, NonEmptyItemOverwrite, useApi } from '@tymely/api';
import { conditionTitles, IPolicySet, IWfPolicy, IOrganization, IUiPolicy, IWorkflow } from '@tymely/atoms';
import { UseQueryOptions } from 'react-query/types/react/types';
import { AxiosError } from 'axios';

import { useOrganizationQuery } from './organization.services';
import { useArgumentsMetadataQuery } from './argument.services';
import { useActionsMetadataQuery } from './action.services';

const INTENT_POLICY_QUERY_KEY = 'intentPolicy';

export type UseFetchWfPolicyQueryArgs = UseQueryOptions<IWfPolicy, AxiosError> & {
    orgId: IOrganization['id'];
    intentId: IPolicySet['id'];
    asSeenAt?: string;
};

export const useFetchWfIntentPolicyQuery = ({ orgId, intentId, asSeenAt, ...options }: UseFetchWfPolicyQueryArgs) => {
    const organizationQuery = useOrganizationQuery(orgId);
    const actionsMetadataQuery = useActionsMetadataQuery();
    const argumentsMetadataQuery = useArgumentsMetadataQuery();
    const orgPolicySetId = organizationQuery.data?.org_policy_set_id;

    let enabled = Boolean(orgPolicySetId && intentId && argumentsMetadataQuery.data && actionsMetadataQuery.data);
    if (typeof options.enabled === 'boolean') {
        enabled = enabled && options.enabled;
    }

    const api = useApi();
    return useQuery<IWfPolicy, AxiosError>({
        queryKey: [INTENT_POLICY_QUERY_KEY, orgId, intentId, asSeenAt],
        queryFn: async () => {
            const policy = (await api.get('wf-policy/search', {
                params: {
                    org_policy_set_id: orgPolicySetId ?? 0,
                    policy_set_id: intentId,
                    as_seen_at: asSeenAt ?? undefined,
                },
            })) as IWfPolicy;

            return {
                orgId: orgId,
                intentId,
                workflows: policy.workflows.map((wf) => ({
                    ...wf,
                    conditions: wf.conditions.map((cond) => ({
                        ...cond,
                        argument_metadata: argumentsMetadataQuery.data!.filter(
                            (md) => md.id === cond.argument_metadata_id,
                        )[0],
                    })),
                    actions: wf.actions.map((action) => ({
                        ...action,
                        action_metadata: actionsMetadataQuery.data!.filter(
                            (md) => md.id === action.action_metadata_id,
                        )[0],
                    })),
                })),
            };
        },
        ...options,
        enabled,
        meta: {
            errorMessage: "Failed to fetch Tandem-Report's wf-policy",
        },
    });
};

const isNumeric = (value: string | number): boolean => {
    return Number(value) === 0 || !!Number(value);
};

const formatUpsertPolicySchemaErrors = (error: ISchemaError, policy: IUiPolicy): string[] => {
    const errors = uniqBy(error.detail, (err) => err.loc.join());
    return (
        errors
            .filter(
                (err) => !(err.loc[3] === 'conditions' && err.loc[5] === 'id' && err.type === 'value_error.missing'),
            )
            // The first filter handles server quirks, don't remove it and don't combine it with any filter below!
            .map((err) => {
                if (err.loc[1] === 'workflows' && err.loc.length > 2 && isNumeric(err.loc[2])) {
                    const workflow = policy.workflows[err.loc[2] as number];
                    const workflowId = workflow.id || `"${workflow.title}"`;

                    if (err.type === 'value_error' && err.loc[3] === 'conditions' && isNumeric(err.loc[4])) {
                        const condition = workflow.conditions[err.loc[4] as number];
                        return `Workflow ${workflowId}: bad condition "${conditionTitles[condition.predicate]}" for "${
                            condition.argument_metadata.title
                        }": ${err.msg}`;
                    }

                    return `Workflow ${workflowId}: ${err.msg}`;
                }

                return `${err.loc.join('->')}: ${err.msg}`;
            })
    );
};

export const useUpsertWfIntentPolicyMutation = (props: {
    onSuccess?: (policy: IWfPolicy) => void;
    onError?: (error: string[]) => void;
}) => {
    const api = useApi();
    const queryClient = useQueryClient();
    const argumentsMetadataQuery = useArgumentsMetadataQuery();
    const actionsMetadataQuery = useActionsMetadataQuery();

    const { onSuccess } = props;
    return useMutation({
        mutationKey: [INTENT_POLICY_QUERY_KEY],
        mutationFn: async ({
            policy,
            orgPolicySetId,
            mode,
        }: {
            policy: IUiPolicy;
            orgPolicySetId: IOrganization['org_policy_set_id'];
            mode: 'overwrite' | 'append';
        }) => {
            const copy = { ...policy };
            const updated = (await api.put('wf-policy', copy, {
                params: { org_policy_set_id: orgPolicySetId, policy_set_id: copy.intentId, mode },
            })) as IWfPolicy;
            return { ...copy, ...updated };
        },
        onSuccess: async (data, { policy }) => {
            data = {
                ...data,
                workflows: data.workflows.map((wf) => ({
                    ...wf,
                    conditions: wf.conditions.map((cond) => ({
                        ...cond,
                        argument_metadata: argumentsMetadataQuery.data!.filter(
                            (md) => md.id === cond.argument_metadata_id,
                        )[0],
                    })),
                    actions: wf.actions.map((action) => ({
                        ...action,
                        action_metadata: actionsMetadataQuery.data!.filter(
                            (md) => md.id === action.action_metadata_id,
                        )[0],
                    })),
                })),
            };
            queryClient.setQueryData([INTENT_POLICY_QUERY_KEY, policy.orgId, policy.intentId], () => data);
            return onSuccess?.({ ...policy, ...data });
        },
        onError: (error, { policy }) => {
            if (error instanceof SchemaError) {
                props.onError?.(formatUpsertPolicySchemaErrors(error.detail, policy));
            } else if (error instanceof NonEmptyItemOverwrite) {
                props.onError?.([error.message]);
            } else {
                props.onError?.([
                    `Failed saving policy for intent (id=${policy.intentId}, org_id=${policy.orgId}): ${
                        (error as any).message
                    }`,
                ]);
            }
        },
    });
};

export const useInvalidateWfIntentPolicyCacheMutation = (props: {
    onSuccess?: (policy: IWfPolicy) => void;
    onError?: (error: string[]) => void;
}) => {
    const queryClient = useQueryClient();
    const { onSuccess } = props;

    const api = useApi();
    return useMutation({
        mutationKey: [INTENT_POLICY_QUERY_KEY],
        mutationFn: async ({ policy }: { policy: Pick<IUiPolicy, 'orgId' | 'intentId'> }) => {
            const updated = (await api.put(
                'wf-policy/invalidate-cache',
                {},
                {
                    params: { org_policy_set_id: policy.orgId, policy_set_id: policy.intentId },
                },
            )) as IWfPolicy;
            return { ...policy, ...updated };
        },

        onSuccess: async (data, { policy }) => {
            queryClient.setQueryData([INTENT_POLICY_QUERY_KEY, policy.orgId, policy.intentId], () => data);
            return onSuccess?.({ ...policy, ...data });
        },
        onError: (error, { policy }) => {
            if (error instanceof NonEmptyItemOverwrite) {
                props.onError?.([error.message]);
            } else {
                props.onError?.([
                    `Failed invaliding policy cache for intent (id=${policy.intentId}, org_id=${policy.orgId}): ${
                        (error as any).message
                    }`,
                ]);
            }
        },
    });
};

export const useUpdateWorkflow = (onSuccess?: (intent: IWorkflow) => void) => {
    const api = useApi();
    const changeStatus = useMutation({
        mutationFn: (args: [IWorkflow['id'], IWorkflow['status']]) =>
            api.put(`workflow/${args[0]}/status`, { status: args[1] }) as Promise<IWorkflow>,
        onSuccess,
    });

    return {
        changeStatus,
    };
};
