import { JSONSchema7 } from 'json-schema';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { datadogLogs } from '@datadog/browser-logs';
import { useApi } from '@tymely/api';
import {
    IConsumptionChannel,
    IOrganization,
    IOrganizationCreate,
    IOrganizationUpdate,
    IPolicySet,
    selectedChannelsAtom,
} from '@tymely/atoms';
import { AxiosError } from 'axios';
import uniqBy from 'lodash/uniqBy';
import { useEffect } from 'react';

import { useSetAlert } from './alerts.services';

export const ORGANIZATION_QUERY_KEY = 'organization';
export const ORGANIZATIONS_QUERY_KEY = 'organizations';

export const useOrganizationSchemaQuery = () => {
    const api = useApi();
    return useQuery<JSONSchema7>({
        queryKey: ['organization-schema'],
        queryFn: () => api.get('organization/schema') as Promise<JSONSchema7>,
    });
};

export const useOrganizationQuery = (orgId: IOrganization['id'], enabled = true) => {
    const api = useApi();
    return useQuery<IOrganization>({
        queryKey: [ORGANIZATION_QUERY_KEY, orgId],
        queryFn: () => api.get(`organization/${orgId}`),
        enabled: Boolean(orgId && enabled),
    });
};

export const useEditOrganizationMutation = () => {
    const setAlert = useSetAlert();
    const api = useApi();
    const queryClient = useQueryClient();

    return useMutation<IOrganization, AxiosError, IOrganizationUpdate>({
        mutationFn: (org) => api.put(`organization/${org.id}`, org),
        onSuccess: async (org) => {
            await Promise.all([
                queryClient.invalidateQueries({ queryKey: ['organization', org.id] }),
                queryClient.invalidateQueries({ queryKey: ['organizations'] }),
            ]);
        },
        onError: (error) => {
            setAlert(error.message, 'error', 5000, 'Failed editing organization.');
        },
    });
};

export const useCreateOrganizationMutation = () => {
    const setAlert = useSetAlert();
    const api = useApi();
    const queryClient = useQueryClient();
    return useMutation<IOrganization, AxiosError, IOrganizationCreate>({
        mutationFn: (org) => api.post('organization', org),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['organizations'] });
        },
        onError: (error) => {
            setAlert(error.message, 'error', 5000, 'Failed creating organization.');
        },
    });
};

export const useOrganizationsQuery = (liveOnly?: boolean) => {
    const api = useApi();
    return useQuery<IOrganization[]>({
        queryKey: [ORGANIZATIONS_QUERY_KEY],
        queryFn: async () => {
            const orgs = (await api.get('organizations/list', {
                params: { limit: 1000, live_only: liveOnly },
            })) as IOrganization[];

            return orgs
                .sort((o1, o2) => {
                    if (o1.is_live_client === o2.is_live_client) return o1.name > o2.name ? 1 : -1;
                    if (o1.is_live_client && !o2.is_live_client) return -1;
                    return 1;
                })
                .filter((org) => (liveOnly ? org.is_live_client : true));
        },
    });
};

export const useSelectedOrganizations = () => {
    const selectedChannels = useSelectedChannels();
    return uniqBy(
        selectedChannels.map((channel) => channel.organization),
        (org) => org.id,
    );
};

export const useChannels = (): { channels: IConsumptionChannel[]; isLoading: boolean } => {
    const organizationsQuery = useOrganizationsQuery(false);

    if (!organizationsQuery.data) {
        return { channels: [], isLoading: organizationsQuery.isFetching };
    }

    const channels = organizationsQuery.data
        .map((org) => {
            const channels = org.config.consumption?.channels;

            if (channels === undefined) {
                return [];
            }

            return channels.map((channelConfig) => ({
                uiId: `${org.name}-${channelConfig.name}`,
                organization: org,
                ...channelConfig,
            }));
        })
        .flat();

    return {
        channels,
        isLoading: organizationsQuery.isFetching,
    };
};

export function isQAChannel(channel: IConsumptionChannel) {
    return channel.name === 'qa';
}

export const useSetSelectedChannels = () => useSetRecoilState(selectedChannelsAtom);
export const useSelectedChannels = () => useRecoilValue(selectedChannelsAtom);

export function useFetchWfPolicyIsImplemented() {
    const api = useApi();
    return async (orgPolicySetId: IOrganization['org_policy_set_id'], policySetId: IPolicySet['id']) => {
        const data = (await api.get('wf-policy/is-implemented', {
            params: { org_policy_set_id: orgPolicySetId, policy_set_id: policySetId },
        })) as { is_implemented: boolean };
        return data.is_implemented ?? false;
    };
}

export const useHasPolicy = (orgPolicySetId: number, policySetId: number | null) => {
    const fetchWfPolicyIsImplemented = useFetchWfPolicyIsImplemented();
    const query = useQuery<boolean>({
        queryKey: [ORGANIZATIONS_QUERY_KEY, orgPolicySetId, policySetId],
        queryFn: async () => {
            if (policySetId) {
                return fetchWfPolicyIsImplemented(orgPolicySetId, policySetId);
            }
            return false;
        },

        enabled: Boolean(orgPolicySetId && policySetId),
        staleTime: Infinity,
    });

    const { data: hasPolicy } = query;

    useEffect(() => {
        if (hasPolicy === undefined) return;
        if (!hasPolicy) {
            datadogLogs.logger.info(`Policy does not exist for intent ${policySetId}`);
        }
    }, [hasPolicy]);

    return query;
};
