import { useMemo, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { chain } from 'lodash';
import { useApi } from '@tymely/api';
import { IComment, TicketHistoryInfo } from '@tymely/atoms';

import { sortCommentsByInquiryDate, useSetTicketProps, useTicket } from './ticket.services';
import { useSetCommentIdToFocusOn, useCommentIdToFocusOn } from './comment.services';
import { useAppMode } from './mode';

export const POLL_COMMENTS_QUERY_KEY = 'pollComments';
export const TICKET_HISTORY_QUERY_KEY = 'ticketHistory';

export const groupComments = (comments: IComment[]): { date: string; comments: IComment[] }[] =>
    chain(comments)
        .groupBy((comment: IComment) => new Date(comment.inquiry_date).toDateString())
        .map((comments, date) => ({ date, comments }))
        .sortBy((comment) => new Date(comment.date), ['asc'])
        .value();

export const useConversation = () => {
    const api = useApi();
    const ticket = useTicket();
    const setTicketProps = useSetTicketProps();
    const commentIdToFocusOn = useCommentIdToFocusOn();
    const setCommentIdToFocusOn = useSetCommentIdToFocusOn();
    const { isOnline } = useAppMode();

    const { data: updates, refetch } = useQuery<IComment[]>({
        queryKey: [POLL_COMMENTS_QUERY_KEY, ticket.id],
        queryFn: () => {
            if (!ticket?.id) return Promise.reject('Ticket is not loaded');
            const lastComment = (ticket?.comments || []).at(-1);
            return api.get(`ticket/${ticket.id}/comments`, {
                params: {
                    created_since: lastComment?.created_date,
                    updated_since: lastComment?.updated_at || lastComment?.created_date || undefined,
                },
            });
        },
        refetchOnMount: false,
        enabled: false,
        retry: false,
        initialData: ticket?.comments || [],
    });

    useEffect(() => {
        if (updates?.length) {
            const oldComments = ticket?.comments || [];
            const newComments = sortCommentsByInquiryDate(updates).filter(
                ({ id }) => !oldComments.find((c) => c.id === id),
            );
            if (newComments.length) {
                setTicketProps({ comments: oldComments.concat(newComments) });
                if (isOnline) {
                    setCommentIdToFocusOn(undefined);
                }
            }
        }
    }, [updates]);

    const { data: history, isFetched: historyFetched } = useQuery<TicketHistoryInfo>({
        queryKey: [TICKET_HISTORY_QUERY_KEY, ticket.id],
        queryFn: () => {
            return api.get(
                `organization/${ticket.organization_id}/customer/${ticket.origin_customer_id}/ticket_history`,
                {
                    params: {
                        ticket_id: ticket.id,
                        async: true,
                    },
                },
            );
        },
        enabled: !ticket.organization.disabled,
        staleTime: Infinity,
    });

    const groupedComments = useMemo(() => groupComments(ticket.comments), [ticket]);

    return {
        ticket,
        comments: groupedComments,
        refetch,
        history,
        commentIdToFocusOn,
        loaded: Boolean(ticket?.comments) && historyFetched,
        setCommentIdToFocusOn,
    };
};
