import * as Diff from 'diff';
import { ComponentProps, memo, useEffect, useMemo, useRef } from 'react';
import CodeMirror, { ReactCodeMirrorRef } from '@uiw/react-codemirror';
import { json, jsonParseLinter, jsonLanguage } from '@codemirror/lang-json';
import { linter, lintGutter } from '@codemirror/lint';
import { githubLight } from '@uiw/codemirror-theme-github';
import {
    jsonCompletion,
    jsonSchemaLinter,
    stateExtensions,
    handleRefresh,
    jsonSchemaHover,
} from 'codemirror-json-schema';
import { JSONSchema7 } from 'json-schema';
import { Decoration, EditorView, hoverTooltip } from '@codemirror/view';
import { StateEffect, Range, StateField } from '@codemirror/state';

export const CodeEditor = memo(
    (props: {
        code: string;
        language: 'json' | 'jinja2';
        jsonSchema?: JSONSchema7;
        diff?: Diff.Change[];
        onChange?: (code: string) => void;
        autoFormat?: boolean;
        editorProps?: ComponentProps<typeof CodeMirror>;
    }) => {
        const code = useMemo(() => {
            if (props.diff) {
                return props.diff.map((chunk) => chunk.value).join('');
            }
            return props.code;
        }, [props.code, props.diff]);

        const onCodeChange = (newCode: string) => {
            props.onChange?.(newCode);
        };

        const extensions = [];
        switch (props.language) {
            case 'json':
                extensions.push(json());
                if (!props.diff) {
                    extensions.push(linter(jsonParseLinter()));
                }
                if (props.jsonSchema) {
                    extensions.push(
                        linter(jsonSchemaLinter(), {
                            needsRefresh: handleRefresh,
                        }),
                        jsonLanguage.data.of({
                            autocomplete: jsonCompletion(),
                        }),
                        hoverTooltip(jsonSchemaHover()),
                        stateExtensions(props.jsonSchema),
                    );
                }
                break;
        }

        const highlight_effect = useMemo(() => StateEffect.define<Range<Decoration>[]>(), []);
        const highlight_extension = useMemo(
            () =>
                StateField.define({
                    create() {
                        return Decoration.none;
                    },
                    update(value, transaction) {
                        value = value.map(transaction.changes);
                        for (const effect of transaction.effects) {
                            if (effect.is(highlight_effect)) value = value.update({ add: effect.value, sort: true });
                        }
                        return value;
                    },
                    provide: (f) => EditorView.decorations.from(f),
                }),
            [],
        );
        const added_decoration = useMemo(
            () =>
                Decoration.mark({
                    attributes: { style: 'background-color:#65E2AE; color: white' },
                }),
            [],
        );
        const removed_decoration = useMemo(
            () =>
                Decoration.mark({
                    attributes: { style: 'background-color:#FE7CA3; color:white' },
                }),
            [],
        );

        const refs = useRef<ReactCodeMirrorRef>({});
        useEffect(() => {
            if (refs.current?.view && refs.current?.state && props.diff) {
                let at = 0;
                for (const chunk of props.diff) {
                    if (chunk.added) {
                        refs.current?.view.dispatch({
                            effects: highlight_effect.of([added_decoration.range(at, at + chunk.value.length)]),
                        });
                    } else if (chunk.removed) {
                        refs.current?.view.dispatch({
                            effects: highlight_effect.of([removed_decoration.range(at, at + chunk.value.length)]),
                        });
                    }
                    at = at + chunk.value.length;
                }
            }
        }, [refs.current, code, props.diff]);

        return (
            <CodeMirror
                ref={refs}
                value={code}
                theme={githubLight}
                extensions={[!props.diff && lintGutter(), highlight_extension, ...extensions].filter(Boolean)}
                onChange={onCodeChange}
                readOnly={!props.onChange}
                basicSetup={{
                    syntaxHighlighting: true,
                    foldGutter: true,
                    tabSize: 4,
                }}
                {...props.editorProps}
            />
        );
    },
);
CodeEditor.displayName = 'CodeEditor';
