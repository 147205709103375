export enum AppMode {
    Online = 'online',
    Labeling = 'labeling',
    Analyst = 'analyst',
    Admin = 'admin',
    Training = 'training',
    QA = 'qa',
}

export const AppModes = Object.fromEntries(Object.entries(AppMode).map(([label, value]) => [value, label])); // online => Online
