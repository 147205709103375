import {
    useArgumentsQuery as _useArgumentsQuery,
    AppMode,
    useAppMode,
    useSelectedComment,
    useTicket,
} from '@tymely/services';
import sortBy from 'lodash/sortBy';
import React from 'react';

const useArgumentsQuery = ({ version }: { version?: string }) => {
    const comment = useSelectedComment();
    const { appMode } = useAppMode();
    const ticket = useTicket();

    const asSeenAtComment = React.useMemo(() => {
        if (version) {
            return version;
        }
        if (comment && appMode === AppMode.QA) {
            const srotedComments = sortBy(ticket.comments, 'created_date');
            const foundComment = srotedComments.find(
                ({ is_customer, created_date }) => is_customer && created_date > comment.created_date,
            );
            return foundComment?.created_date;
        }
        return undefined;
    }, [version, ticket, comment]);

    return _useArgumentsQuery({
        commentId: comment?.id,
        argsVersion: asSeenAtComment,
        enabled: !!comment?.selected_intent_id,
    });
};

export default useArgumentsQuery;
