import { useMemo, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';
import { routes } from '@tymely/config';
import { AppMode, AppModes } from '@tymely/atoms';

export { AppMode, AppModes } from '@tymely/atoms';

export const useAppMode = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isOnline = location.pathname.startsWith(routes.onlineTicket) || location.search.includes('online');
    const isOffline = location.pathname.startsWith(routes.ticket);
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const queryParamMode = useMemo(() => {
        const mode = queryParams.get('mode') as AppMode;
        return mode in AppModes ? mode : AppMode.Training;
    }, [queryParams]);
    const offlineMode = isOffline ? queryParamMode : undefined;
    const appMode = isOnline ? AppMode.Online : (offlineMode as AppMode);
    const isAdmin = appMode === AppMode.Admin;
    const isChat = (isAdmin || isOnline) && queryParams.has('chat');
    const state = location.state || {};
    const shiftEnd = queryParams.has('eos') || Boolean(state.shiftEnd) || isChat;
    const navigateTicket = useCallback(
        (ticketId: number | string | null, mode = AppMode.Labeling, replace?: boolean) => {
            const search = qs.parse(location.search, { ignoreQueryPrefix: true });
            search.mode = mode;
            if (isChat) {
                delete search.chat;
                const url = `${routes.ticket}/${ticketId || ''}?${qs.stringify(search)}`;
                window.open(url, '_blank');
            } else if (location.pathname.startsWith(routes.onlineTicket)) {
                navigate(`${routes.onlineTicket}?${qs.stringify(search)}`, { state });
            } else {
                navigate(`${routes.ticket}/${ticketId || ''}?${qs.stringify(search)}`, { replace, state });
            }
        },
        [state, navigate],
    );

    const switchMode = useCallback(
        (mode: AppMode) => {
            if (!isOnline && !isOffline) return;

            if (mode === AppMode.Online) {
                navigate(routes.onlineTicket, { state: { shiftEnd: false } });
                return;
            }
            if (isOnline) {
                navigate(`${routes.ticket}?mode=${mode}`, { state });
            } else {
                navigate(`${location.pathname}?mode=${mode}`, { state });
            }
        },
        [state, navigate, location, isOnline],
    );

    const switchShift = useCallback(() => {
        navigate(`${location.pathname}${location.search}`, { state: { shiftEnd: !shiftEnd } });
    }, [state, location, navigate]);

    return {
        isOnline,
        isChat,
        appMode,
        navigateTicket,
        switchMode,
        switchShift,
        shiftEnd,
    };
};
