import { TabContext, TabList } from '@mui/lab';
import React from 'react';
import { Box, styled, Tab } from '@mui/material';
import {
    IWaitType,
    useTicketLoadingStatus,
    useSelectedCommentDecisionQuery,
    useWaitWhileLoading,
} from '@tymely/services';
import { useRecoilState } from 'recoil';
import { isPolicyEvaluated } from '@tymely/atoms';

import useArgumentsQuery from './useArgumentsQuery';
import { PolicyExplanation } from './PolicyExplanation';
import TextArgumentSection from './TextArgumentSection';
import SystemArgumentSection from './SystemArgumentSection';
import ArgumentsProvider, { ArgumentsTabsProviderProps } from './ArgumentsTabsProvider';
import CalculatePolicyButton from './CalculatePolicyButton';

const TabPanel = styled(Box)`
    padding: ${({ theme }) => theme.spacing(2)};
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    max-height: 100%;
    padding-bottom: ${({ theme }) => theme.spacing(4)};
`;

type TabType = 'text-args' | 'sys-args' | 'explanation';

type ArgumentsTabsProps = Omit<ArgumentsTabsProviderProps, 'children' | 'onEditStatus'>;

export type ArgumentsTabsRef = {
    reset: () => void;
};

const ArgumentsTabs = React.forwardRef<ArgumentsTabsRef, ArgumentsTabsProps>(({ version, ...rest }) => {
    const [tab, setTab] = React.useState<TabType>('text-args');
    const argsQuery = useArgumentsQuery({ version });
    const decisionQuery = useSelectedCommentDecisionQuery();
    const [policyEvaluated] = useRecoilState(isPolicyEvaluated);

    const decision = decisionQuery.data;
    const showAnalyzedArgs = decision ? Boolean(argsQuery.data) : policyEvaluated;
    const showSystemArgumentSection = !argsQuery.isError && showAnalyzedArgs;
    const showExplanationSection =
        !argsQuery.isError &&
        showAnalyzedArgs &&
        (!!decision?.explanation?.length || !!decision?.condition_lineage?.length);

    const loadingStatus = useTicketLoadingStatus();
    useWaitWhileLoading(IWaitType.POLICY_EVALUATION, !!loadingStatus);

    const onTabChange = (_: React.SyntheticEvent, value: TabType) => setTab(value);

    return (
        <TabContext value={tab}>
            <ArgumentsProvider version={version} {...rest}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mr: 2 }}>
                    <TabList onChange={onTabChange} sx={{ width: '100%', flex: 1 }}>
                        {!argsQuery.isError && <Tab label="Text" value="text-args" />}
                        {showSystemArgumentSection && <Tab label="System" value="sys-args" />}
                        {showExplanationSection && <Tab label="Explanation" value="explanation" />}
                    </TabList>

                    <CalculatePolicyButton sx={{ mb: 1 }} />
                </Box>
                {!argsQuery.isError && (
                    <TabPanel hidden={tab !== 'text-args'}>
                        <TextArgumentSection />
                    </TabPanel>
                )}
                {showSystemArgumentSection && (
                    <TabPanel hidden={tab !== 'sys-args'}>
                        <SystemArgumentSection />
                    </TabPanel>
                )}
                {showExplanationSection && (
                    <TabPanel hidden={tab !== 'explanation'}>
                        <PolicyExplanation />
                    </TabPanel>
                )}
            </ArgumentsProvider>
        </TabContext>
    );
});
ArgumentsTabs.displayName = 'ArgumentsTabs';

export default ArgumentsTabs;
