import React from 'react';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Box, Tooltip } from '@mui/material';
import { useTicketLoadingStatus } from '@tymely/services';

import { useArgumentsTabsContext } from './ArgumentsTabsProvider';

const CalculatePolicyButton = (props: LoadingButtonProps) => {
    const { onEvaluatePolicy } = useArgumentsTabsContext();
    const loadingStatus = useTicketLoadingStatus();

    const button = (
        <LoadingButton
            loading={!!loadingStatus}
            color="success"
            variant="contained"
            {...props}
            onClick={onEvaluatePolicy}
        >
            Calculate Policy
        </LoadingButton>
    );

    return loadingStatus ? (
        <Tooltip open title={loadingStatus} placement="top">
            <Box>{button}</Box>
        </Tooltip>
    ) : (
        button
    );
};

export default CalculatePolicyButton;
