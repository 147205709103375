import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import enUS from 'date-fns/locale/en-US';
import format from 'date-fns/format';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';
import { useIsMutating } from '@tanstack/react-query';
import { EDIT_TEMPLATE_MUTATION_KEY } from '@tymely/services';

import useTemplateUpdateListener, { TemplateUpdateInfo } from '../hooks/useTemplateUpdateListener';
import { useTemplateEditor } from './TemplateEditorProvider';

const UpdatedTemplateDialog = () => {
    const savingAtRef = React.useRef(false);
    const [info, setInfo] = React.useState<TemplateUpdateInfo>();
    const { templateId } = useTemplateEditor();
    const isMutatingTemplate = useIsMutating([EDIT_TEMPLATE_MUTATION_KEY]);

    useTemplateUpdateListener((data) => {
        if (!savingAtRef.current) {
            setInfo(data);
        }
    });

    React.useEffect(() => {
        savingAtRef.current = isMutatingTemplate > 0;
    }, [isMutatingTemplate]);

    const onOpenInNewTab = () => setInfo(undefined);

    let updatedAtLabel = undefined;
    if (info?.date) {
        updatedAtLabel = format(info.date, 'd MMM yyyy, HH:mm', { locale: enUS });
    }

    return (
        <Dialog open={!!info}>
            <DialogTitle>You are viewing an old version of this template (ID {templateId})</DialogTitle>
            <DialogContent>
                <DialogContentText variant="body1">
                    This template has been recently updated updated by <strong>{info?.updatedBy}</strong> at{' '}
                    {updatedAtLabel}, open in a new tab to view the latest changes
                </DialogContentText>
                <DialogActions>
                    <Link
                        to={`/template-editor/${templateId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={onOpenInNewTab}
                    >
                        <Button variant="contained" startIcon={<OpenInNewIcon />}>
                            Open in a new tab
                        </Button>
                    </Link>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default UpdatedTemplateDialog;
