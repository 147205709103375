import React, {
    useState,
    useRef,
    useCallback,
    useMemo,
    useEffect,
    memo,
    MutableRefObject,
    FunctionComponent,
} from 'react';
import {
    TextField,
    CircularProgress,
    OutlinedInput,
    styled,
    useTheme,
    alpha,
    FormControl,
    Theme,
    IconButton,
    selectClasses,
    SxProps,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useKeys } from '@tymely/utils';
import { Nullable } from '@global/types';
import { IOrganization, ITicket } from '@tymely/atoms';
import { useOrganizationsQuery } from '@tymely/services';
import { useDebounce } from '@tymely/utils';

import MultiSelect, { MultiSelectProps, Option } from '../MultiSelect';

const Container = styled(FormControl)(({ theme }) => ({
    width: theme.spacing(50),
    flexDirection: 'row',
}));

const inputStyle = (theme: Theme, value: unknown) => ({
    '& .MuiInputBase-root': {
        color: theme.palette.common.white,
        paddingRight: value ? theme.spacing(4) : 0,
    },
    '& .MuiInputBase-input': {
        padding: theme.spacing(1),
    },
    fieldset: {
        display: 'none',
    },
});

const rootStyle = (theme: Theme) => ({
    width: theme.spacing(25),
    color: theme.palette.common.white,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    borderRadius: theme.spacing(0.5),
});

const StyledSelect = styled(MultiSelect)(({ theme }) => ({
    ...rootStyle(theme),
    width: '60%',
    borderLeft: '1px solid',
    borderLeftColor: alpha(theme.palette.common.white, 0.25),
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    [`& .${selectClasses.disabled}`]: {
        color: alpha(theme.palette.common.white, 0.45),
    },
})) as FunctionComponent<MultiSelectProps<number>>;

const StyledInput = styled(TextField)(({ theme, value }) => ({
    ...rootStyle(theme),
    ...inputStyle(theme, value),
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
}));

const SelectInput = styled(OutlinedInput)(({ theme, value }) => inputStyle(theme, value));

type SearchProps = {
    searchApi: (params: { query: string; org: number; maxResults?: number }, signal: AbortSignal) => Promise<ITicket[]>;
    sx?: SxProps;
    onSubmit: (item: ITicket) => void;
};

export const TicketSearchInOrg = memo((props: SearchProps) => {
    const [selectedOrgs, setOrgs] = useState<IOrganization[]>([]);
    const [inputValue, setInput] = useState('');
    const theme = useTheme();
    const { data: orgs, isFetching } = useOrganizationsQuery(true);
    const [searching, setIsSearching] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

    const searchQuery = useDebounce(inputValue, 300) || '';
    const controller = useRef(new AbortController());

    useEffect(() => {
        setNotFound(false);
    }, [inputValue, selectedOrgs]);

    const onOrgsSelect = useCallback(
        (options: Nullable<Option<number>[]>) => {
            if (!orgs || !options) return setOrgs([]);
            setOrgs(
                options
                    .map<IOrganization | undefined>(({ value }) => orgs.find(({ id }) => id === value))
                    .filter(Boolean),
            );
        },
        [orgs],
    );

    const onInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setInput(event.target.value);
    }, []);

    useKeys(
        ['Enter'],
        () => {
            controller.current.abort();
            controller.current = new AbortController();
            if (searchQuery.length >= 3 && selectedOrgs.length) {
                setIsSearching(true);
                props
                    .searchApi(
                        {
                            query: searchQuery,
                            org: selectedOrgs[0].id,
                            maxResults: 1,
                        },
                        controller.current.signal,
                    )
                    .then((results) => {
                        if (!results.length) {
                            setNotFound(true);
                        } else {
                            props.onSubmit(results[0]);
                        }
                    })
                    .finally(() => setIsSearching(false));
            }
        },
        { ref: inputRef },
    );

    const onClean = useCallback(() => {
        setInput('');
    }, []);

    const inputStatus = useMemo(() => {
        if (inputValue && !selectedOrgs.length) {
            return 'Please select organization';
        }
        if (notFound) {
            return 'Ticket not found';
        }
        return '';
    }, [inputValue, selectedOrgs, notFound]);

    const value = useMemo(() => {
        return selectedOrgs.map(({ id, name }) => ({ value: id, name }));
    }, [selectedOrgs]);

    const options = useMemo(() => {
        return orgs?.map(({ id, name }) => ({ value: id, name })) ?? [];
    }, [orgs]);

    return (
        <Container sx={props.sx}>
            <StyledInput
                InputLabelProps={{
                    shrink: true,
                    style: {
                        color: inputStatus ? theme.palette.error.contrastText : theme.palette.primary.contrastText,
                    },
                }}
                placeholder="Search by external ID"
                label={inputStatus}
                value={inputValue}
                onChange={onInputChange}
                variant="outlined"
                ref={inputRef}
                InputProps={{
                    endAdornment: (
                        <>
                            {searching ? <CircularProgress sx={{ position: 'absolute', right: 8 }} size={20} /> : null}
                            {inputValue && !searching ? (
                                <IconButton sx={{ position: 'absolute', right: 0 }} onClick={onClean}>
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            ) : null}
                        </>
                    ),
                }}
            />
            <StyledSelect
                value={value}
                placeholder="Organization..."
                input={<SelectInput />}
                multiple={false}
                label="Organization"
                options={options}
                loading={isFetching}
                sx={{
                    [`& .${selectClasses.outlined}`]: !value.length && {
                        textFillColor: alpha(theme.palette.common.white, 0.45),
                    },
                }}
                onOptsSelect={onOrgsSelect}
            />
        </Container>
    );
});

TicketSearchInOrg.displayName = 'TicketSearchInOrg';
